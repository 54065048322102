import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Tabs,
  Badge,
  Image,
  Radio,
  Space,
  Checkbox,
  Form,
  Select,
  Input,
  Modal,
  Collapse,
  Button,
  Carousel,
} from "antd";
import CurrencyFormat from "react-currency-format";
import styled from "styled-components";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Fade from "react-reveal/Fade";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const text = `
* All calculations, rates and payments shown are GUIDELINES ONLY and are not quotations. Final rates and payments can only be determined by your Finance House once they have assessed all your information (completed in a finance application form). By using this calculator, you hereby INDEMNIFY Mega Theron Group, its holding, associated or partner companies against any loss or liability which you may suffer as a result of using the calculator. All information provided to you on this website is for illustrative purposes only and may not be considered to be ADVICE.
`;
const { Panel } = Collapse;
const StyledCheckbox = styled(Checkbox)`
  font-weight: 400 !important;
  font-size: 14px !important;
  color: black !important;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: red !important;
    border: 0px solid black !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: red!important;
}
.ant-checkbox-checked::after {
  background-color: transparent !important;
  border-color: red !important;
  border: 0px solid red !important;
}
  }
`;
const StyledInput = styled(Input)`

  padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: transparent !important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid black !important;
  border-radius: 0px;
  color: white!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;

    box-shadow:none !important;
}
  :active{
    border-color: red !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    
     box-shadow:none !important;
   
  }
  }
`;
const StyledModalTestDrive = styled(Modal)`
  .ant-modal-header {
    display: none;
    border-radius: 0.5em !important;
    text-align: center !important;
    border-bottom: 0px solid #f0f0f0 !important;
  }
  .ant-modal-content {
    // padding-left: 1.5em;
    // padding-right: 1.5em;
    padding-top: 0.1em;
    // height: 36em !important;
    max-width: 40em;
    margin: auto !important;
    background-color: white !important;
  }
  @media (max-width: 1100px) {
    .ant-modal-content {
      padding-left: 1.5em;
      padding-right: 1.5em;
      padding-top: 0.1em;
      padding-bottom: 1.5em;
      height: 100% !important;
      // width: 100vw;
      margin: auto !important;
      background-color: white !important;
    }
  }
`;
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const settings = {
  dots: false,
  infinite: true,
  speed: 400,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
class FinanceBox extends React.Component {
  state = {
    amountValue: 30000,
    monthsValue: 12,
    depositValue: 0,
    balloonValue: 0,
    interestValue: 7,
  };
  handleAmountChange = (value) => {
    this.setState({ amountValue: value });
  };
  handleDepositChange = (value) => {
    this.setState({ depositValue: value });
  };
  handleMonthsChange = (value) => {
    this.setState({ monthsValue: value });
  };
  handleBalloonChange = (value) => {
    this.setState({ balloonValue: value });
  };
  handleInterestChange = (value) => {
    this.setState({ interestValue: value });
  };

  render() {
    let {
      amountValue,
      depositValue,
      monthsValue,
      balloonValue,
      interestValue,
    } = this.state;

    return (
      <Row style={{ marginBottom: "2em" }}>
        <Col span={14}>
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}>Vehicle Amount</span>
            <span className="color-red" style={{ float: "right" }}>
              <CurrencyFormat
                value={amountValue}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"R "}
                //format='R ### ### ### ###'
                renderText={(amountValue) => <div>{amountValue}</div>}
              />
            </span>
          </h5>
          <InputRange
            step={100}
            maxValue={600000}
            minValue={30000}
            value={amountValue}
            onChange={this.handleAmountChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Deposit Amount</span>{" "}
            <span className="color-red" style={{ float: "right" }}>
              <CurrencyFormat
                value={depositValue}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"R "}
                //format='R ### ### ### ###'
                renderText={(depositValue) => <div>{depositValue}</div>}
              />
            </span>
          </h5>
          <InputRange
            step={100}
            maxValue={amountValue - 30000}
            minValue={0}
            value={depositValue}
            onChange={this.handleDepositChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Over</span>
            <span className="color-red" style={{ float: "right" }}>
              {monthsValue} Month{monthsValue > 1 && "s"}
            </span>
          </h5>
          <InputRange
            step={12}
            maxValue={96}
            minValue={12}
            value={monthsValue}
            onChange={this.handleMonthsChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Interest Rate</span>
            <span className="color-red" style={{ float: "right" }}>
              {interestValue}%
            </span>
          </h5>
          <InputRange
            step={0.25}
            maxValue={17}
            minValue={7}
            value={interestValue}
            onChange={this.handleInterestChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Balloon Payment</span>
            <span className="color-red" style={{ float: "right" }}>
              {balloonValue}%
            </span>
          </h5>
          <InputRange
            step={1}
            maxValue={8}
            minValue={0}
            value={balloonValue}
            onChange={this.handleBalloonChange}
          />
        </Col>{" "}
        <Col span={10}>
          <Display
            months={monthsValue}
            amount={amountValue}
            deposit={depositValue}
            balloon={balloonValue}
            interest={interestValue}
          />
        </Col>
      </Row>
    );
  }
}
class Display extends React.Component {
  state = {
    APR: 0.05,
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.calculateAPR();
    }
  }

  calculateAPR = () => {
    let { amount } = this.props;

    if (1000 < amount && amount < 5000) {
      this.setState({ APR: 0.05 });
    }
    if (5000 < amount && amount < 10000) {
      this.setState({ APR: 0.1 });
    }
    if (10000 < amount && amount < 15000) {
      this.setState({ APR: 0.15 });
    }
    if (15000 < amount && amount < 20000) {
      this.setState({ APR: 0.2 });
    }
  };

  calculateMonthlyRepayment = () => {
    let { amount, months, deposit, interest, balloon } = this.props;
    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal;
    let monthlyRepaymentLast = Math.round(
      monthlyRepayment < 0 ? "0" : monthlyRepayment
    );

    //let { amount, months, deposit, interest } = this.props;
    //let amountInterestDec = interest / 100;
    //let amountInterestMonth = amountInterestDec / 12;
    //let amountInterest = amountInterestMonth;
    //let montsCalc = Math.pow(1 + amountInterest, months);
    //let monthlyRepayment1 = amountInterest * montsCalc;
    //let monthlyRepayment2 = montsCalc - 1;
    //let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    //let amountDeDeposit = amount - deposit;
    //let monthlyRepaymentTotal = amountDeDeposit * monthlyRepaymentIntersest;
    //let monthlyRepayment = monthlyRepaymentTotal + 94;

    return (
      <h4 className="finance-box-p color-red" style={{ marginTop: "-1em" }}>
        <CurrencyFormat
          value={monthlyRepaymentLast}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"R "}
          //format='R ### ### ### ###'
          renderText={(monthlyRepaymentLast) => (
            <div>{monthlyRepaymentLast}</div>
          )}
        />
      </h4>
    );
  };
  calculateInterest = () => {
    let { amount, months, deposit, interest, balloon } = this.props;

    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal;
    let monthlyRepaymentInterest =
      monthlyRepayment * months - (amount - deposit);
    let monthlyRepaymentLast = Math.round(
      monthlyRepaymentInterest < 0 ? "0" : monthlyRepaymentInterest
    );
    return (
      <span>
        <h4 className="finance-box-p color-red">
          <CurrencyFormat
            value={monthlyRepaymentLast}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"R "}
            //format='R ### ### ### ###'
            renderText={(monthlyRepaymentLast) => (
              <div>{monthlyRepaymentLast}</div>
            )}
          />
        </h4>

        <p style={{ marginBottom: "0em" }}>Interest Amount</p>
      </span>
    );
  };
  calculateTotal = () => {
    let { amount, months, deposit, interest, balloon } = this.props;

    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal * months;
    let monthlyRepaymentall = monthlyRepayment;
    let monthlyRepaymentallLast = Math.round(
      monthlyRepaymentall < 0 ? "0" : monthlyRepaymentall
    );
    return (
      <span>
        <h4 className="finance-box-p color-red">
          <CurrencyFormat
            value={monthlyRepaymentallLast}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"R "}
            //format='R ### ### ### ###'
            renderText={(monthlyRepaymentallLast) => (
              <div>{monthlyRepaymentallLast}</div>
            )}
          />
        </h4>

        <p style={{ marginBottom: "0em" }}>Total Repayment</p>
      </span>
    );
  };
  calculateAmount = () => {
    let { amount, months, deposit, interest, balloon } = this.props;

    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal;
    let monthlyRepaymentLast = Math.round(
      amount - deposit < 0 ? "0" : amount - deposit
    );
    return (
      <span>
        <h4 className="finance-box-p color-red">
          <CurrencyFormat
            value={monthlyRepaymentLast}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"R "}
            //format='R ### ### ### ###'
            renderText={(monthlyRepaymentLast) => (
              <div>{monthlyRepaymentLast}</div>
            )}
          />
        </h4>

        <p style={{ marginBottom: "0em" }}>Amount To Finance</p>
      </span>
    );
  };
  percentageAPR = () => {
    return <h4 className="finance-box-p color-red">{this.state.APR * 100}%</h4>;
  };

  render() {
    return (
      <div className="finance-box-flex">
        {/*<DisplayChild func={this.percentageAPR()} text='Interest Rate' />*/}
        <DisplayChild
          func={this.calculateMonthlyRepayment()}
          intr={this.calculateInterest()}
          total={this.calculateTotal()}
          fin={this.calculateAmount()}
          text="Monthly Repayment"
        />
      </div>
    );
  }
}

const DisplayChild = ({ func, text, intr, total, fin }) => {
  return (
    <span>
      {func} <p style={{ marginBottom: "0em" }}>{text}</p>
      <br />
      {fin}
      <br />
      {intr}
      <br />
      {total}
    </span>
  );
};
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}
const onChange = (e) => {
  console.log(`checked = ${e.target.checked}`);
};
class StockDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      valueRadio: "Speak to a Dealer",
      blackVisible: true,
      blueVisible: false,
      grayVisible: false,
      greenVisible: false,
      purpleVisible: false,
      silverVisible: false,
      technicGrayVisible: false,
      whiteVisible: false,
    };

    this.onFinish = this.onFinish.bind(this);
  }
  componentDidMount() {
    console.log(this.props.selectedStock);
    document.documentElement.scrollTop = 0;
  }
  blackClick = () => {
    this.setState({
      blackVisible: true,
      blueVisible: false,
      grayVisible: false,
      greenVisible: false,
      purpleVisible: false,
      silverVisible: false,
      technicGrayVisible: false,
      whiteVisible: false,
    });
  };
  blueClick = () => {
    this.setState({
      blackVisible: false,
      blueVisible: true,
      grayVisible: false,
      greenVisible: false,
      purpleVisible: false,
      silverVisible: false,
      technicGrayVisible: false,
      whiteVisible: false,
    });
  };
  grayClick = () => {
    this.setState({
      blackVisible: false,
      blueVisible: false,
      grayVisible: true,
      greenVisible: false,
      purpleVisible: false,
      silverVisible: false,
      technicGrayVisible: false,
      whiteVisible: false,
    });
  };
  greenClick = () => {
    this.setState({
      blackVisible: false,
      blueVisible: false,
      grayVisible: false,
      greenVisible: true,
      purpleVisible: false,
      silverVisible: false,
      technicGrayVisible: false,
      whiteVisible: false,
    });
  };
  purpleClick = () => {
    this.setState({
      blackVisible: false,
      blueVisible: false,
      grayVisible: false,
      greenVisible: false,
      purpleVisible: true,
      silverVisible: false,
      technicGrayVisible: false,
      whiteVisible: false,
    });
  };
  silverClick = () => {
    this.setState({
      blackVisible: false,
      blueVisible: false,
      grayVisible: false,
      greenVisible: false,
      purpleVisible: false,
      silverVisible: true,
      technicGrayVisible: false,
      whiteVisible: false,
    });
  };
  technicGrayClick = () => {
    this.setState({
      blackVisible: false,
      blueVisible: false,
      grayVisible: false,
      greenVisible: false,
      purpleVisible: false,
      silverVisible: false,
      technicGrayVisible: true,
      whiteVisible: false,
    });
  };
  whiteClick = () => {
    this.setState({
      blackVisible: false,
      blueVisible: false,
      grayVisible: false,
      greenVisible: false,
      purpleVisible: false,
      silverVisible: false,
      technicGrayVisible: false,
      whiteVisible: true,
    });
  };
  onChangeRadio = (e) => {
    console.log("radio checked", e.target.value);
    this.setState({
      valueRadio: e.target.value,
    });
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  handleSubmit = (values) => {
    this.onFinish(values);
  };
  onFinish = (values) => {
    var { email, stockNumber, make, series, modelDesc, modelYear, valueRadio } =
      this.props.selectedStock;
    //var { valueRadio } = this.state.valueRadio;
    const MSG = {
      to: "leads@megatheron.co.za",
      from: "info@mtgroup.co.za",
      //to: [  //"leads@megatheron.co.za", "monique@moniqs-interiors.com"],
      subject: "New Vehicle Enquiry",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/z5SfxC9/mtgroup.png" width="300" height="85" style="display: block; border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Vehicle Enquiry</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new Vehicle Enquiry from <br /> Chery website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.name}<br />
                          <strong> Number </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.number}<br />
                          <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.email}<br />
                  
                          <strong> Make </strong> &nbsp;&nbsp;:&nbsp;&nbsp; TIGGO<br />
                          <strong> Model </strong> &nbsp;&nbsp;:&nbsp;&nbsp; 7 PRO MAX MY 23<br />
                  
                          <strong> Request </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${this.state.valueRadio}<br />
                 
                      
                       
                             <br /><br />
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2024@CHERY</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        visible: true,
        submitDisabled: true,
        name: "",
        number: "",
        email: "",
      });
    });
  };
  scrollOnclick() {
    // window.location.reload();
    document.documentElement.scrollTop = 0;
  }
  render() {
    const { dotPosition } = this.state;
    console.log(this.props.selectedStock);
    var {
      make,
      series,
      modelDesc,
      modelYear,
      email,
      number,
      regDate,
      extColour,
      intColour,
      retailPriceIncl,
      fuelType,
      capacityCC,
      bodyType,
      transmission,
      engineSize,
      vehicleDrive,
      unladenWeight,
      axles,
      doors,
      cylinders,
      passengers,
      immobiliser,
      dateBuilt,
      extrasAndAccessories,
      comments,
      price,
      branch,
      brand,
      email,
      onSale,
      condition,
      mileage,
      fuelTankSize,
      degreeCamera,
      fourWheelDrive,
      abs,
      driverAssistance,
      airBags,
      aircon,
      alarm,
      mobileCarPlay,
      automaticLights,
      automaticWipers,
      autonomousBraking,
      blindSpotMonitoring,
      bluetooth,
      brakeAssist,
      climateControl,
      cruiseControl,
      daytimeLights,
      electricParkingBrake,
      elctricWindows,
      electronicStability,
      collisionWarning,
      headDisplay,
      heatedSeats,
      hillAssist,
      hillControl,
      keylessEntry,
      laneWarning,
      laneAssist,
      leatherSeats,
      ledLights,
      navigation,
      paddleShift,
      parkControl,
      powerBoot,
      powerSeat,
      remoteStart,
      reverseCamera,
      sunroof,
      touchScreen,
      towbar,
      signRecognition,
      tyreMonitor,
      usbPort,
      voiceRecognition,
      warranty,
      wirelessCharging,
      xenonLights,
      frontLeftImage,
      frontImage,
      frontRightImage,
      rightSideImage,
      wheelsImage,
      backRightImage,
      backImage,
      backLeftImage,
      leftSideImage,
      bootImage,
      frontSeatImage,
      backSeatImage,
      dashPassengerImage,
      dashDriverImage,
      dashDriverSideImage,
      radioImage,
      radioCloseImage,
      gearsImage,
      steeringImage,
      spareKeyImage,
      branchImage,
    } = this.props.selectedStock;
    const images = [
      {
        original: frontLeftImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontLeftImage}`
          : null,
        thumbnail: frontLeftImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontLeftImage}`
          : null,
      },
      {
        original: frontImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontImage}`
          : null,
        thumbnail: frontImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontImage}`
          : null,
      },
      {
        original: frontRightImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontRightImage}`
          : null,
        thumbnail: frontRightImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontRightImage}`
          : null,
      },
      {
        original: rightSideImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${rightSideImage}`
          : null,
        thumbnail: rightSideImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${rightSideImage}`
          : null,
      },
      {
        original: backRightImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backRightImage}`
          : null,
        thumbnail: backRightImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backRightImage}`
          : null,
      },
      {
        original: backImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backImage}`
          : null,
        thumbnail: backImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backImage}`
          : null,
      },
      {
        original: backLeftImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backLeftImage}`
          : null,
        thumbnail: backLeftImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backLeftImage}`
          : null,
      },
      {
        original: leftSideImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${leftSideImage}`
          : null,
        thumbnail: leftSideImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${leftSideImage}`
          : null,
      },
      {
        original: bootImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${bootImage}`
          : null,
        thumbnail: bootImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${bootImage}`
          : null,
      },
      {
        original: backSeatImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backSeatImage}`
          : null,
        thumbnail: backSeatImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backSeatImage}`
          : null,
      },
      {
        original: dashPassengerImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashPassengerImage}`
          : null,
        thumbnail: dashPassengerImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashPassengerImage}`
          : null,
      },
      {
        original: dashDriverImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashDriverImage}`
          : null,
        thumbnail: dashDriverImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashDriverImage}`
          : null,
      },
      {
        original: dashDriverSideImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashDriverSideImage}`
          : null,
        thumbnail: dashDriverSideImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashDriverSideImage}`
          : null,
      },
      {
        original: radioImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${radioImage}`
          : null,
        thumbnail: radioImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${radioImage}`
          : null,
      },
      {
        original: radioCloseImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${radioCloseImage}`
          : null,
        thumbnail: radioCloseImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${radioCloseImage}`
          : null,
      },
      {
        original: gearsImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${gearsImage}`
          : null,
        thumbnail: gearsImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${gearsImage}`
          : null,
      },
      {
        original: steeringImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${steeringImage}`
          : null,
        thumbnail: steeringImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${steeringImage}`
          : null,
      },
      {
        original: spareKeyImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${spareKeyImage}`
          : null,
        thumbnail: spareKeyImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${spareKeyImage}`
          : null,
      },
    ];
    return (
      <div className="wrap-body-inner" style={{ margin: "auto" }}>
        <Row className="desktop-view">
          <Col xs={24} md={13}>
            <div>
              <Carousel
                autoplay
                dotPosition={dotPosition}
                dots={true}
                effect="fade"
              >
                <span>
                  <video
                    style={{
                      width: "100%",
                      height: "100vh",
                      objectFit: "cover",
                      overflowClipBox: "content-box",
                      overflow: "clip",
                      objectPosition: "center",
                    }}
                    autoPlay
                    muted
                    loop
                  >
                    <source
                      src={`${process.env.PUBLIC_URL}/images/chery-new/Chery-Tiggo-8-Pro-Max-My23.webm`}
                      type="video/mp4"
                    ></source>
                  </video>
                </span>
              </Carousel>
            </div>
          </Col>
          <Col xs={24} md={10}>
            <div
              className="m-t-lg-30"
              style={{
                textAlign: "center",
                padding: "0.5em",
                minWidth: "100%",
                margin: "auto",
                position: "absolute",
                top: "50%",
                left: "55%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Fade bottom>
                <div>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "0.5em",
                      maxWidth: "45em",
                      margin: "auto",
                    }}
                  >
                    <Row style={{ margin: "auto" }}>
                      <Col span={24} style={{ marginBottom: "0.5em" }}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/chery-new/logo-8pm-my23.png`}
                          alt=""
                          style={{
                            width: "18em",
                            marginTop: "0.5em",
                            marginBottom: "1em",
                          }}
                        />
                        <span
                          style={{
                            fontSize: "25px",
                            textTransform: "none",
                            fontFamily: "TacticSans",
                            display: "block",
                            color: "grey",
                          }}
                        >
                          Enjoy Your First Class
                        </span>
                      </Col>
                      <Col span={8}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <Link
                            // onClick={() => this.props.fetchSingleStock(stock)}
                            to={`/tiggo-8-pro-max-my-23`}
                          >
                            <span
                              style={{
                                fontSize: "12px",
                                textTransform: "none",
                                fontFamily: "TacticSansBd",
                                display: "block",
                                color: "#c50d27",
                              }}
                            >
                              24.6" Ultra Curved
                              <br />
                            </span>
                            <span
                              style={{
                                fontSize: "12px",
                                textTransform: "none",
                                fontFamily: "TacticSans",
                                color: "grey",
                              }}
                            >
                              Dual Screen
                              <br />
                            </span>
                          </Link>
                        </h4>
                      </Col>
                      <Col span={8}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <Link
                            // onClick={() => this.props.fetchSingleStock(stock)}
                            to={`/tiggo-8-pro-max-my-23`}
                          >
                            <span
                              style={{
                                fontSize: "12px",
                                textTransform: "none",
                                fontFamily: "TacticSansBd",
                                display: "block",
                                color: "#c50d27",
                              }}
                            >
                              Eight-Speaker Sony
                              <br />
                            </span>
                            <span
                              style={{
                                fontSize: "12px",
                                textTransform: "none",
                                fontFamily: "TacticSans",
                                color: "grey",
                              }}
                            >
                              Sound System
                              <br />
                            </span>
                          </Link>
                        </h4>
                      </Col>
                      <Col span={8}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <Link
                            // onClick={() => this.props.fetchSingleStock(stock)}
                            to={`/tiggo-8-pro-max-my-23`}
                          >
                            <span
                              style={{
                                fontSize: "12px",
                                textTransform: "none",
                                fontFamily: "TacticSansBd",
                                display: "block",
                                color: "#c50d27",
                              }}
                            >
                              Ventilated and Heated
                              <br />
                            </span>
                            <span
                              style={{
                                fontSize: "12px",
                                textTransform: "none",
                                fontFamily: "TacticSans",
                                color: "grey",
                              }}
                            >
                              Front Seats
                              <br />
                            </span>
                          </Link>
                        </h4>
                      </Col>
                    </Row>
                  </div>
                  <div className="bg1-gray-2">
                    <Form
                      style={{ maxWidth: "45em", margin: "auto" }}
                      // id='book-service'
                      onFinish={this.handleSubmit}
                      initialValues={{
                        remember: true,
                      }}
                      name="wrap"
                      labelCol={{ flex: "110px" }}
                      labelAlign="left"
                      labelWrap
                      wrapperCol={{ flex: 1 }}
                      colon={false}
                    >
                      <div
                        className="m-b-lg-20"
                        style={{ maxWidth: "45em", margin: "auto" }}
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <Form.Item
                              name="name"
                              rules={[{ required: true, message: false }]}
                              hasFeedback
                            >
                              <StyledInput placeholder="Name" />
                            </Form.Item>
                          </div>

                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <Form.Item
                              name="number"
                              rules={[
                                {
                                  //max:10,
                                  //min:10,
                                  required: true,
                                  message: false,
                                  //type: "number",
                                },
                              ]}
                              hasFeedback
                            >
                              <StyledInput placeholder="Number" />
                            </Form.Item>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <Form.Item
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: false,
                                  type: "email",
                                },
                              ]}
                              hasFeedback
                            >
                              <StyledInput placeholder="Email" />
                            </Form.Item>
                          </div>

                          <div className="text-center col-sm-12 col-md-12 col-lg-12">
                            <Form.Item
                              name="book"
                              rules={[{ required: false, message: false }]}
                            >
                              <Radio.Group
                                onChange={this.onChangeRadio}
                                value={this.state.valueRadio}
                              >
                                <Space
                                  direction="horizontal"
                                  style={{
                                    display: window.isPhone ? "grid" : "",
                                  }}
                                >
                                  <Radio
                                    style={{ color: "black" }}
                                    value="Speak to a Dealer"
                                  >
                                    Speak to a Dealer
                                  </Radio>
                                  <Radio
                                    style={{ color: "black" }}
                                    value="Book a Test Drive"
                                  >
                                    Book a Test Drive
                                  </Radio>
                                </Space>
                              </Radio.Group>
                            </Form.Item>
                          </div>
                          <div className="col-lg-12 m-t-lg-12 text-center">
                            <Form.Item>
                              <Button
                                style={{
                                  fontFamily: "Arial",
                                  border: "1px solid #CD2934",
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  borderRadius: "0em",
                                  backgroundColor: "#CD2934",
                                  color: "white",
                                  height: "55px",
                                  width: "100%",
                                  paddingLeft: "3em",
                                  paddingRight: "3em",
                                  textShadow: "none",
                                  margin: "auto",
                                  marginTop: "0em",
                                }}
                                type="primary"
                                htmlType="submit"
                              >
                                Send Request
                              </Button>{" "}
                            </Form.Item>{" "}
                            <p
                              style={{
                                color: "black",
                                //marginBottom: "2.5em",
                                marginTop: "0em",
                                fontSize: "13px",
                                lineHeight: "1.5em",
                              }}
                            >
                              By submitting this form, you agree to our{" "}
                              <span
                                style={{
                                  fontSize: "13px",
                                  textDecoration: "underline",
                                }}
                              >
                                <Link style={{ color: "black" }} to="/terms">
                                  Terms of Use
                                </Link>
                              </span>{" "}
                              and{" "}
                              <span
                                style={{
                                  fontSize: "13px",
                                  textDecoration: "underline",
                                }}
                              >
                                <Link
                                  style={{ color: "black" }}
                                  to="/privacy-policy"
                                >
                                  Privacy Statement
                                </Link>
                              </span>
                              .
                            </p>{" "}
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>{" "}
                </div>
              </Fade>
            </div>
          </Col>
        </Row>
        <Row className="mobile-view">
          <Col xs={24} md={13}>
            <div>
              <Carousel
                autoplay
                dotPosition={dotPosition}
                dots={true}
                effect="fade"
              >
                <span>
                  <video
                    style={{
                      width: "100%",
                      height: "30em",
                      objectFit: "cover",
                      overflowClipBox: "content-box",
                      overflow: "clip",
                      objectPosition: "center",
                    }}
                    autoPlay
                    muted
                    loop
                  >
                    <source
                      src={`${process.env.PUBLIC_URL}/images/chery-new/Chery-Tiggo-8-Pro-Max-My23.webm`}
                      type="video/mp4"
                    ></source>
                  </video>
                </span>
              </Carousel>
            </div>
          </Col>
          <Col xs={24} md={10}>
            <div
              className="m-t-lg-30"
              style={{
                textAlign: "center",
                padding: "0.5em",
                minWidth: "100%",
                margin: "auto",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  padding: "0.5em",
                  maxWidth: "45em",
                  margin: "auto",
                }}
              >
                <Row style={{ margin: "auto" }}>
                  <Col span={24} style={{ marginBottom: "0.5em" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/chery-new/logo-8pm-my23.png`}
                      alt=""
                      style={{
                        width: "18em",
                        marginTop: "0.5em",
                        marginBottom: "1em",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "25px",
                        textTransform: "none",
                        fontFamily: "TacticSans",
                        display: "block",
                        color: "grey",
                      }}
                    >
                      Enjoy Your First Class
                    </span>
                  </Col>
                  <Col
                    span={24}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <div
                      className="f-16 m-t-lg-5  mn-cl"
                      style={{ color: "black" }}
                    >
                      FROM
                    </div>
                    <div
                      className="f-40 m-t-lg-0 mn-cl"
                      style={{ color: "black" }}
                    >
                      <CurrencyFormat
                        value="609 900"
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"R"}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <h4
                      className="product-name"
                      style={{ marginBottom: "0em", paddingBottom: 0 }}
                    >
                      <Link
                        // onClick={() => this.props.fetchSingleStock(stock)}
                        to={`/tiggo-8-pro-max-my-23`}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            textTransform: "none",
                            fontFamily: "TacticSansBd",
                            display: "block",
                            color: "#c50d27",
                          }}
                        >
                          24.6" Ultra Curved
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            textTransform: "none",
                            fontFamily: "TacticSans",
                            color: "grey",
                          }}
                        >
                          Dual Screen
                          <br />
                        </span>
                      </Link>
                    </h4>
                  </Col>
                  <Col span={24}>
                    <h4
                      className="product-name"
                      style={{ marginBottom: "0em", paddingBottom: 0 }}
                    >
                      <Link
                        // onClick={() => this.props.fetchSingleStock(stock)}
                        to={`/tiggo-8-pro-max-my-23`}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            textTransform: "none",
                            fontFamily: "TacticSansBd",
                            display: "block",
                            color: "#c50d27",
                          }}
                        >
                          Eight-Speaker Sony
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            textTransform: "none",
                            fontFamily: "TacticSans",
                            color: "grey",
                          }}
                        >
                          Sound System
                          <br />
                        </span>
                      </Link>
                    </h4>
                  </Col>
                  <Col span={24}>
                    <h4
                      className="product-name"
                      style={{ marginBottom: "0em" }}
                    >
                      <Link
                        // onClick={() => this.props.fetchSingleStock(stock)}
                        to={`/tiggo-8-pro-max-my-23`}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            textTransform: "none",
                            fontFamily: "TacticSansBd",
                            display: "block",
                            color: "#c50d27",
                          }}
                        >
                          Ventilated and Heated
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            textTransform: "none",
                            fontFamily: "TacticSans",
                            color: "grey",
                          }}
                        >
                          Front Seats
                          <br />
                        </span>
                      </Link>
                    </h4>
                  </Col>
                </Row>
              </div>
              <div className="bg1-gray-2">
                <Form
                  style={{ maxWidth: "45em", margin: "auto" }}
                  // id='book-service'
                  onFinish={this.handleSubmit}
                  initialValues={{
                    remember: true,
                  }}
                  name="wrap"
                  labelCol={{ flex: "110px" }}
                  labelAlign="left"
                  labelWrap
                  wrapperCol={{ flex: 1 }}
                  colon={false}
                >
                  <div
                    className="m-b-lg-20"
                    style={{ maxWidth: "45em", margin: "auto" }}
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <Form.Item
                          name="name"
                          rules={[{ required: true, message: false }]}
                          hasFeedback
                        >
                          <StyledInput placeholder="Name" />
                        </Form.Item>
                      </div>

                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <Form.Item
                          name="number"
                          rules={[
                            {
                              //max:10,
                              //min:10,
                              required: true,
                              message: false,
                              //type: "number",
                            },
                          ]}
                          hasFeedback
                        >
                          <StyledInput placeholder="Number" />
                        </Form.Item>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <Form.Item
                          name="email"
                          rules={[
                            { required: true, message: false, type: "email" },
                          ]}
                          hasFeedback
                        >
                          <StyledInput placeholder="Email" />
                        </Form.Item>
                      </div>

                      <div className="text-center col-sm-12 col-md-12 col-lg-12">
                        <Form.Item
                          name="book"
                          rules={[{ required: false, message: false }]}
                        >
                          <Radio.Group
                            onChange={this.onChangeRadio}
                            value={this.state.valueRadio}
                          >
                            <Space
                              direction="horizontal"
                              style={{
                                display: window.isPhone ? "grid" : "",
                              }}
                            >
                              <Radio
                                style={{ color: "black" }}
                                value="Speak to a Dealer"
                              >
                                Speak to a Dealer
                              </Radio>
                              <Radio
                                style={{ color: "black" }}
                                value="Book a Test Drive"
                              >
                                Book a Test Drive
                              </Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                      <div className="col-lg-12 m-t-lg-12 text-center">
                        <Form.Item>
                          <Button
                            style={{
                              fontFamily: "Arial",
                              border: "1px solid #CD2934",
                              fontSize: "15px",
                              fontWeight: 600,
                              borderRadius: "0em",
                              backgroundColor: "#CD2934",
                              color: "white",
                              height: "55px",
                              width: "100%",
                              paddingLeft: "3em",
                              paddingRight: "3em",
                              textShadow: "none",
                              margin: "auto",
                              marginTop: "0em",
                            }}
                            type="primary"
                            htmlType="submit"
                          >
                            Send Request
                          </Button>{" "}
                        </Form.Item>{" "}
                        <p
                          style={{
                            color: "black",
                            //marginBottom: "2.5em",
                            marginTop: "0em",
                            fontSize: "13px",
                            lineHeight: "1.5em",
                          }}
                        >
                          By submitting this form, you agree to our{" "}
                          <span
                            style={{
                              fontSize: "13px",
                              textDecoration: "underline",
                            }}
                          >
                            <Link style={{ color: "black" }} to="/terms">
                              Terms of Use
                            </Link>
                          </span>{" "}
                          and{" "}
                          <span
                            style={{
                              fontSize: "13px",
                              textDecoration: "underline",
                            }}
                          >
                            <Link
                              style={{ color: "black" }}
                              to="/privacy-policy"
                            >
                              Privacy Statement
                            </Link>
                          </span>
                          .
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </Form>
              </div>{" "}
            </div>
          </Col>
        </Row>
        <section
          className=" m-t-xs-0"
          style={{ margin: "auto", marginTop: "0em", maxWidth: "100em" }}
        >
          <div
            className="col-sm-12 col-md-12 desktop-view"
            style={{ height: "9em", marginTop: "1em" }}
          >
            <Row
              gutter={16}
              style={{
                borderRadius: "1.5em",
              }}
            >
              <Col sm={24} md={18}>
                <Card
                  bordered={false}
                  style={{ padding: 0, backgroundColor: "transparent" }}
                >
                  <h4
                    style={{
                      color: "black",
                      paddingTop: "0em",
                      paddingBottom: "0em",
                      paddingRight: "0.2em",
                      marginTop: "-1em",
                    }}
                    className="product-name "
                  >
                    <span className="f-40" style={{ fontFamily: "TacticSans" }}>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/chery-new/logo-8pm-my23.png`}
                        alt=""
                        style={{ width: "8em" }}
                      />
                      <p
                        className="f-20"
                        style={{ color: "black", fontFamily: "TacticSans" }}
                      >
                        ENJOY YOUR FIRST CLASS
                      </p>
                    </span>
                  </h4>{" "}
                </Card>
              </Col>
              <Col sm={24} md={6}>
                <Card
                  bordered={false}
                  style={{ padding: 0, backgroundColor: "transparent" }}
                >
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <div
                      className="f-12 m-t-lg-0  mn-cl"
                      style={{ color: "black", fontFamily: "TacticSans" }}
                    >
                      FROM
                    </div>
                    <div
                      className="f-40 m-t-lg-0 mn-cl"
                      style={{ color: "black", fontFamily: "TacticSans" }}
                    >
                      <CurrencyFormat
                        value="609 900"
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"R"}
                      />
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>{" "}
          </div>
          {/* <div className='col-sm-12 col-md-12 mobile-view' style={{ height: "9em", marginTop: "1em", marginBottom: "8em" }}>

            <Row

              style={{
                //padding: "2em",
                // boxShadow: "2px 2px 10px 2px #ecececd7",
                borderRadius: "1.5em",
              }}
            >
              <Col sm={24} md={18} style={{ width: "100vw" }}>
                <Card bordered={false} style={{ padding: 0, backgroundColor: "transparent", textAlign: "center" }}>
                  <h4
                    style={{
                      color: "black",
                      paddingTop: "0em",
                      paddingBottom: "0em",
                      paddingRight: "0.2em",
                      marginTop: "-1em",

                    }}
                    className='product-name '

                  >
                    <span className='f-40' style={{ fontFamily: "TacticSans", }}>
                      TIGGO 7 PRO MAX MY 23 <br />
                      <p className='f-20' style={{ color: "black", fontFamily: "TacticSans" }}>FIRST IMPRESSIONS LAST</p>
                    </span>
                  </h4>{" "}
                  <div
                    style={{
                      textAlign: "center",

                    }}
                  >
                    <div className='f-16 m-t-lg-5  mn-cl' style={{ color: "black" }}>
                      FROM
                    </div>
                    <div className='f-40 m-t-lg-0 mn-cl' style={{ color: "black" }}>
                      <CurrencyFormat
                        value="609 900"
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"R"}
                      />
                    </div></div>
                </Card>


              </Col>

            </Row>{" "}

          </div> */}
          <div
            className="row m-t-lg-50"
            style={{ marginBottom: window.isPhone ? "0em" : "50px" }}
          >
            <div className="col-md-12">
              <Tabs
                defaultActiveKey="1"
                onChange={callback}
                tabPosition="top"
                style={{ color: "black" }}
              >
                <TabPane tab="SHOWROOM" key="1">
                  <div className="row m-t-lg-20 m-b-lg-10 desktop-view">
                    <div className="product_detail no-bg p-lg-0">
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="product-img-lg">
                            <Slider {...settings}>
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-1.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-2.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-3.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-4.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-5.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-6.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-7.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-8.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-9.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-10.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-11.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-12.jpg`}
                                alt=""
                              />
                            </Slider>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="row  mobile-view">
                    <div
                      className="row"
                      style={{ marginBottom: "2em", textAlign: "center" }}
                    >
                      <Slider {...settings}>
                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-1.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-2.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-3.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-4.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-5.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-6.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-7.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-8.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-9.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-10.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-11.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-max-my-23-gal-12.jpg`}
                          alt=""
                        />
                      </Slider>
                    </div>
                  </div>{" "}
                </TabPane>
                <TabPane tab="TIGGO 8 PRO MAX MY23" key="2">
                  <div className="m-b-lg-30">
                    <div
                      className="heading-1 "
                      style={{ background: "rgb(197, 13, 39)" }}
                    >
                      <h3
                        className="f-18"
                        style={{ color: "white", paddingLeft: "1em" }}
                      >
                        TIGGO 8 PRO MAX MY23 – ENJOY YOUR FIRST CLASS
                      </h3>
                    </div>

                    <div
                      className="m-b-lg-30 bg-gray-fa bg1-gray-2 p-lg-30 p-xs-15"
                      style={{ backgroundColor: "white", color: "black" }}
                    >
                      <p style={{ color: "black" }}>
                        With luxurious touches in abundance, it’s no surprise
                        that the All New TIGGO 8 Pro Max doesn’t just steal the
                        spotlight, it takes over the entire show. Sporting an
                        eye-catching diamond-shaped front grille and
                        illuminating front logo that exudes first class at first
                        sight.
                      </p>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="EXTERIOR" key="3">
                  <div
                    className="heading-1 "
                    style={{ background: "rgb(197, 13, 39)" }}
                  >
                    <h3
                      className="f-18"
                      style={{ color: "white", paddingLeft: "1em" }}
                    >
                      Exterior
                    </h3>
                  </div>
                  <ul className="product_para-1">
                    <li>
                      <strong>The Face of First Class</strong>
                      <br />
                      <br />
                      This advanced SUV boasts impressive handling and
                      stability, enhanced by the presence-filled 19’’ Aluminium
                      Alloy Rim Wheels that provide a dynamic finish. Alongside
                      LED daytime running lamps that ensure exceptional lighting
                      in any situation.
                      <br />
                      <br />
                      To conquer various terrains and rise above the rest, the
                      TIGGO 8 Pro Max offers an impressive 230mm ground
                      clearance, allowing you to navigate diverse landscapes
                      with ease and confidence.
                      <br />
                      <br />
                      Under the hood, the TIGGO 8 Pro Max is powered by a
                      formidable 2.0L turbo engine equipped with high-pressure
                      cylinder direct injection, delivering 187kW of power and
                      390Nm of torque. This engine gives you an impressive blend
                      of power, efficiency, and performance, ensuring an
                      exhilarating driving experience.
                    </li>
                  </ul>
                </TabPane>
                <TabPane tab="POWER" key="4">
                  <div className="m-b-lg-30">
                    <div className="heading-1 ">
                      <h3 className="f-18">POWER</h3>
                    </div>
                    <ul className="product_para-1">
                      <li>
                        <span>Engine :</span>
                        2.0-litre TGDI
                      </li>
                      <li>
                        <span>Power :</span>
                        187 kW
                      </li>
                      <li>
                        <span>Torque :</span>
                        390 Nm
                      </li>
                    </ul>
                  </div>
                </TabPane>

                <TabPane tab="INTERIOR" key="6">
                  <div
                    className="heading-1 "
                    style={{ background: "rgb(197, 13, 39)" }}
                  >
                    <h3
                      className="f-18"
                      style={{ color: "white", paddingLeft: "1em" }}
                    >
                      Interior
                    </h3>
                  </div>
                  <ul className="product_para-1">
                    <li>
                      <strong>First Class Cabin</strong>
                      <br />
                      <br />
                      The All New TIGGO 8 Pro Max epitomises luxury with an
                      array of opulent features and enhancements that redefine
                      your first class driving experience.
                      <br />
                      <br />
                      Enveloping you in absolute luxury and comfort is the plush
                      leather upholstery that’s hand-stitched to perfection on
                      the seats, steering wheel, and other key touchpoints.
                      <br />
                      <br />
                      Elevating that first class feeling further is the ambient
                      lighting, carefully placed to create a soothing and
                      sophisticated atmosphere within the cabin. To cater to
                      your senses, the TIGGO 8 Pro Max offers a top-tier
                      8-speaker Sony Sound System that delivers an immersive
                      experience with crystal-clear audio quality.
                      <br />
                      <br />
                      The incorporation of Queen Co-pilot seats, sound-dampening
                      Mute Glass and Driver Memory Seats with ventilation pamper
                      you on every journey.
                      <br />
                      <br />
                      The upgraded 360° panoramic camera system sets a new
                      benchmark for first class SUVs, enhancing safety and
                      providing a comprehensive view of the vehicle’s
                      surroundings. Dominating the dashboard is the12.3-inch LCD
                      driver cluster and 12.3–inch centre control LCD screen
                      that seamlessly integrates into one high-resolution Dual
                      Curve Screen.
                      <br />
                      <br />
                      When it comes to safety, there’s a commitment to
                      unwavering confidence and no compromise, highlighted by
                      the inclusion of 9 airbags, ISOFIX, and up to 15 Advanced
                      Driver Assistance Systems (ADAS). Among these features are
                      Automatic Emergency Braking (AEB), intelligent alerts,
                      parking assistance, and Traffic Congestion Assist,
                      collectively contributing to an elevated sense of peace of
                      mind on the road.
                      <br />
                      <br />
                      Overall, the All New TIGGO 8 Pro Max is meticulously
                      designed to provide a luxurious and indulgent driving
                      experience, where every detail is carefully considered to
                      ensure unparalleled comfort, style, and sophistication.
                    </li>
                  </ul>
                </TabPane>
                <TabPane tab="TECHNOLOGY" key="7">
                  <div
                    className="heading-1 "
                    style={{ background: "rgb(197, 13, 39)" }}
                  >
                    <h3
                      className="f-18"
                      style={{ color: "white", paddingLeft: "1em" }}
                    >
                      Technology
                    </h3>
                  </div>
                  <ul className="product_para-1">
                    <li>
                      <strong>Comfort Features</strong>
                      <br />
                      <br />
                      • Advanced Driver Assistance Systems (ADAS)
                      <br />
                      • Privacy glass standard
                      <br />
                      • Eight-speaker Sony sound system
                      <br />
                      • Remote engine start
                      <br />
                      • Android Auto / Apple Carplay
                      <br />
                    </li>
                  </ul>
                </TabPane>
                <TabPane tab="SAFETY" key="8">
                  <div
                    className="heading-1 "
                    style={{ background: "rgb(197, 13, 39)" }}
                  >
                    <h3
                      className="f-18"
                      style={{ color: "white", paddingLeft: "1em" }}
                    >
                      Safety
                    </h3>
                  </div>
                  <ul className="product_para-1">
                    <li>
                      <strong>Safety Features</strong>
                      <br />
                      <br />
                      • 540-Degree Camera System
                      <br />
                      • Traffic Sign Recognition
                      <br />
                      • Adaptive Cruise Control
                      <br />
                      • Emergency Braking Assistance
                      <br />
                      • Autonomous Emergency Braking
                      <br />
                      • Lane Departure Warning
                      <br />
                      • Blind Spot Monitoring
                      <br />
                    </li>
                  </ul>
                </TabPane>
              </Tabs>
            </div>
          </div>
          <Row
            style={{ maxWidth: "90em", margin: "auto" }}
            className="desktop-view"
          >
            <Col
              span={24}
              style={{
                textAlign: "center",
              }}
            >
              <span
                style={{
                  fontSize: "30px",
                  textTransform: "none",
                  fontFamily: "TacticSansBd",
                  display: "block",
                  color: "grey",
                }}
              >
                EXPLORE IN 360°
              </span>
              <iframe
                src="https://chery.co.za/wp-content/uploads/3d_apps/tiggo8promaxmy23/"
                frameborder="0"
                scrolling="no"
                style={{
                  touchActions: "none",
                  height: "30vw",
                  width: "80%",
                  borderRadius: "1em",
                  marginTop: "2em",
                }}
              ></iframe>
            </Col>
          </Row>
          <Row
            style={{ maxWidth: "90em", margin: "auto", marginTop: "2em" }}
            className="mobile-view"
          >
            <Col
              span={24}
              style={{
                textAlign: "center",
              }}
            >
              <span
                style={{
                  fontSize: "22px",
                  textTransform: "none",
                  fontFamily: "TacticSansBd",
                  display: "block",
                  color: "grey",
                }}
              >
                EXPLORE IN 360°
              </span>
              <iframe
                src="https://chery.co.za/wp-content/uploads/3d_apps/tiggo8promaxmy23/"
                frameborder="0"
                scrolling="no"
                style={{
                  touchActions: "none",
                  height: "25em",
                  width: "95%",
                  borderRadius: "1em",
                  marginTop: "2em",
                }}
              ></iframe>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                textAlign: "center",
                marginTop: "3em",
              }}
            >
              <a
                href={
                  "https://chery.co.za/wp-content/uploads/2022/02/CHERY_TIGGO_8-PRO-MAX_PRINT-UPDATED-30Nov-1.pdf"
                }
                target="_blank"
                className="ht-btn ht-btn-default m-t-lg-0 m-b-lg-30 p-l-lg-30 p-r-lg-30"
                style={{ textTransform: "none", borderRadius: "0.4em" }}
              >
                TIGGO 8 PRO MAX MY23 SPECIFICATIONS
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{ marginLeft: "1em" }}
                ></i>
              </a>
            </Col>
          </Row>
          <Row style={{ maxWidth: "100vw", justifyContent: "center" }}>
            <Col span={24} style={{ textAlign: "center", marginTop: "3em" }}>
              <p
                style={{
                  margin: "auto",
                  textTransform: "none",
                  fontSize: "20px",
                  color: "rgb(197, 13, 39)",
                  fontWeight: 600,
                }}
              >
                Pricing
              </p>
              <h3 className="f-weight-300" style={{ paddingTop: 0 }}>
                <strong
                  style={{
                    fontSize: "30px",
                    fontFamily: "TacticSans",
                    textTransform: "none",
                  }}
                >
                  Tiggo 8 Pro Max MY23 Pro Trims
                </strong>
              </h3>
            </Col>
            <Col
              xs={24}
              md={8}
              style={{
                padding: "1em",
                border: "10px solid white",
                borderRadius: "2em",
                background: "linear-gradient(90deg, #0F0F0F 0%, #3F3F3F 100%)",
              }}
            >
              <Col span={24} style={{ textAlign: "center" }}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/chery-new/logo-8pm-my23.png`}
                  alt=""
                  style={{
                    width: "20em",
                    filter: "invert(1)",
                    marginTop: "1em",
                  }}
                />
                <h4 className="product-name" style={{ marginBottom: "0em" }}>
                  <span
                    style={{
                      fontSize: "18px",
                      textTransform: "none",
                      fontFamily: "TacticSans",
                      display: "block",
                      color: "white",
                    }}
                  >
                    1.6T EXECUTIVE MY23
                    <br />
                  </span>
                </h4>
              </Col>
              <Col span={24}>
                <div
                  className="hover-img "
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Link
                    className="product-img"
                    // onClick={() => this.props.fetchSingleStock(stock)}
                    to={`/tiggo-8-pro-max-my-23`}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/chery-new/tiggo-8-pm-my23.webp`}
                      alt=""
                      style={{
                        width: "60%",
                        margin: "auto",
                      }}
                    />
                  </Link>
                </div>
              </Col>
              <Row>
                <Col span={24}>
                  <div
                    className=" m-t-lg-0 mn-cl"
                    style={{
                      fontSize: "30px",
                      color: "black",
                      textAlign: "center",
                      color: "#c50d27",
                      fontWeight: 700,
                    }}
                  >
                    <CurrencyFormat
                      value="609 900"
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"R"}
                    />
                  </div>
                </Col>
                <Col
                  span={24}
                  style={{
                    textAlign: "center",
                    marginBottom: "1em",
                  }}
                >
                  <Link
                    to={`#book-service`}
                    className="ht-btn ht-btn-default m-t-lg-10 p-l-lg-30 p-r-lg-30"
                    style={{
                      textTransform: "none",
                      borderRadius: "0.4em",
                    }}
                  >
                    TEST DRIVE
                    <i
                      class="fa fa-angle-right"
                      aria-hidden="true"
                      style={{ marginLeft: "1em" }}
                    ></i>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              md={8}
              style={{
                padding: "1em",
                border: "10px solid white",
                borderRadius: "2em",

                background: "linear-gradient(90deg, #0F0F0F 0%, #3F3F3F 100%)",
              }}
            >
              <Col span={24} style={{ textAlign: "center" }}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/chery-new/logo-8pm-my23.png`}
                  alt=""
                  style={{
                    width: "20em",
                    filter: "invert(1)",
                    marginTop: "1em",
                  }}
                />
                <h4 className="product-name" style={{ marginBottom: "0em" }}>
                  <span
                    style={{
                      fontSize: "18px",
                      textTransform: "none",
                      fontFamily: "TacticSans",
                      display: "block",
                      color: "white",
                    }}
                  >
                    2.0T EXECUTIVE MY23
                    <br />
                  </span>
                </h4>
              </Col>
              <Col span={24}>
                <div
                  className="hover-img "
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Link
                    className="product-img"
                    // onClick={() => this.props.fetchSingleStock(stock)}
                    to={`/tiggo-8-pro-max-my-23`}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/chery-new/tiggo-8-pm-my23.webp`}
                      alt=""
                      style={{
                        width: "60%",
                        margin: "auto",
                      }}
                    />
                  </Link>
                </div>
              </Col>
              <Row>
                <Col span={24}>
                  <div
                    className=" m-t-lg-0 mn-cl"
                    style={{
                      fontSize: "30px",
                      color: "black",
                      textAlign: "center",
                      color: "#c50d27",
                      fontWeight: 700,
                    }}
                  >
                    <CurrencyFormat
                      value="669 900"
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"R"}
                    />
                  </div>
                </Col>
                <Col
                  span={24}
                  style={{
                    textAlign: "center",
                    marginBottom: "1em",
                  }}
                >
                  <Link
                    to={`#book-service`}
                    className="ht-btn ht-btn-default m-t-lg-10 p-l-lg-30 p-r-lg-30"
                    style={{
                      textTransform: "none",
                      borderRadius: "0.4em",
                    }}
                  >
                    TEST DRIVE
                    <i
                      class="fa fa-angle-right"
                      aria-hidden="true"
                      style={{ marginLeft: "1em" }}
                    ></i>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ maxWidth: "100vw", justifyContent: "center" }}>
            <Col span={24} style={{ textAlign: "center", marginTop: "3em" }}>
              <h3 className="f-weight-300" style={{ paddingTop: 0 }}>
                <strong
                  style={{
                    fontSize: "30px",
                    fontFamily: "TacticSans",
                    textTransform: "none",
                  }}
                >
                  Color Options
                </strong>
              </h3>
            </Col>
          </Row>
          {this.state.blackVisible ? (
            <div>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img
                    //className="banner-img-home"
                    style={{
                      width: window.isPhone ? "100%" : "50%",
                    }}
                    src={`${process.env.PUBLIC_URL}/images/chery-new/color-pro-8-max-my-23/black-1.webp`}
                    alt="logo"
                  />
                </Col>
              </Row>
            </div>
          ) : this.state.blueVisible ? (
            <div>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img
                    //className="banner-img-home"
                    style={{
                      width: window.isPhone ? "100%" : "50%",
                    }}
                    src={`${process.env.PUBLIC_URL}/images/chery-new/color-pro-8-max-my-23/blue-1.webp`}
                    alt="logo"
                  />
                </Col>
              </Row>
            </div>
          ) : this.state.grayVisible ? (
            <div>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img
                    //className="banner-img-home"
                    style={{
                      width: window.isPhone ? "100%" : "50%",
                    }}
                    src={`${process.env.PUBLIC_URL}/images/chery-new/color-pro-8-max-my-23/gray-1.webp`}
                    alt="logo"
                  />
                </Col>
              </Row>
            </div>
          ) : this.state.greenVisible ? (
            <div>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img
                    //className="banner-img-home"
                    style={{
                      width: window.isPhone ? "100%" : "50%",
                    }}
                    src={`${process.env.PUBLIC_URL}/images/chery-new/color-pro-8-max-my-23/green-1.webp`}
                    alt="logo"
                  />
                </Col>
              </Row>
            </div>
          ) : this.state.purpleVisible ? (
            <div>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img
                    //className="banner-img-home"
                    style={{
                      width: window.isPhone ? "100%" : "50%",
                    }}
                    src={`${process.env.PUBLIC_URL}/images/chery-new/color-pro-8-max-my-23/purple-1.webp`}
                    alt="logo"
                  />
                </Col>
              </Row>
            </div>
          ) : this.state.silverVisible ? (
            <div>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img
                    //className="banner-img-home"
                    style={{
                      width: window.isPhone ? "100%" : "50%",
                    }}
                    src={`${process.env.PUBLIC_URL}/images/chery-new/color-pro-8-max-my-23/silver-1.webp`}
                    alt="logo"
                  />
                </Col>
              </Row>
            </div>
          ) : this.state.technicGrayVisible ? (
            <div>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img
                    //className="banner-img-home"
                    style={{
                      width: window.isPhone ? "100%" : "50%",
                    }}
                    src={`${process.env.PUBLIC_URL}/images/chery-new/color-pro-8-max-my-23/technic-gray-1.webp`}
                    alt="logo"
                  />
                </Col>
              </Row>
            </div>
          ) : this.state.whiteVisible ? (
            <div>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img
                    //className="banner-img-home"
                    style={{
                      width: window.isPhone ? "100%" : "50%",
                    }}
                    src={`${process.env.PUBLIC_URL}/images/chery-new/color-pro-8-max-my-23/white-1.webp`}
                    alt="logo"
                  />
                </Col>
              </Row>
            </div>
          ) : null}
          <Row
            style={{
              margin: "auto",
              marginTop: "1em",
              marginBottom: "1em",
              textAlign: "center",
            }}
          >
            <Col span={24} style={{ margin: "auto" }}>
              {this.state.blackVisible ? (
                <p
                  style={{
                    fontFamily: "TacticSans",
                    marginBottom: 0,
                    textAlign: "center",
                    marginTop: "0em",
                    padding: 0,
                    color: "black",
                    marginTop: window.isPhone ? "1em" : "0em",
                    textTransform: "none",
                    fontWeight: 300,
                    fontSize: "26px",
                  }}
                >
                  <strong style={{ fontFamily: "TacticSans" }}>Black</strong>
                </p>
              ) : this.state.blueVisible ? (
                <p
                  style={{
                    fontFamily: "TacticSans",
                    marginBottom: 0,
                    textAlign: "center",
                    marginTop: "0em",
                    padding: 0,
                    color: "black",
                    marginTop: window.isPhone ? "1em" : "0em",
                    textTransform: "none",
                    fontWeight: 300,
                    fontSize: "26px",
                  }}
                >
                  <strong style={{ fontFamily: "TacticSans" }}>Blue</strong>
                </p>
              ) : this.state.grayVisible ? (
                <p
                  style={{
                    fontFamily: "TacticSans",
                    marginBottom: 0,
                    textAlign: "center",
                    marginTop: "0em",
                    padding: 0,
                    color: "black",
                    marginTop: window.isPhone ? "1em" : "0em",
                    textTransform: "none",
                    fontWeight: 300,
                    fontSize: "26px",
                  }}
                >
                  <strong style={{ fontFamily: "TacticSans" }}>Gray</strong>
                </p>
              ) : this.state.greenVisible ? (
                <p
                  style={{
                    fontFamily: "TacticSans",
                    marginBottom: 0,
                    textAlign: "center",
                    marginTop: "0em",
                    padding: 0,
                    color: "black",
                    marginTop: window.isPhone ? "1em" : "0em",
                    textTransform: "none",
                    fontWeight: 300,
                    fontSize: "26px",
                  }}
                >
                  <strong style={{ fontFamily: "TacticSans" }}>Green</strong>
                </p>
              ) : this.state.purpleVisible ? (
                <p
                  style={{
                    fontFamily: "TacticSans",
                    marginBottom: 0,
                    textAlign: "center",
                    marginTop: "0em",
                    padding: 0,
                    color: "black",
                    marginTop: window.isPhone ? "1em" : "0em",
                    textTransform: "none",
                    fontWeight: 300,
                    fontSize: "26px",
                  }}
                >
                  <strong style={{ fontFamily: "TacticSans" }}>Purple</strong>
                </p>
              ) : this.state.silverVisible ? (
                <p
                  style={{
                    fontFamily: "TacticSans",
                    marginBottom: 0,
                    textAlign: "center",
                    marginTop: "0em",
                    padding: 0,
                    color: "black",
                    marginTop: window.isPhone ? "1em" : "0em",
                    textTransform: "none",
                    fontWeight: 300,
                    fontSize: "26px",
                  }}
                >
                  <strong style={{ fontFamily: "TacticSans" }}>Silver</strong>
                </p>
              ) : this.state.technicGrayVisible ? (
                <p
                  style={{
                    fontFamily: "TacticSans",
                    marginBottom: 0,
                    textAlign: "center",
                    marginTop: "0em",
                    padding: 0,
                    color: "black",
                    marginTop: window.isPhone ? "1em" : "0em",
                    textTransform: "none",
                    fontWeight: 300,
                    fontSize: "26px",
                  }}
                >
                  <strong style={{ fontFamily: "TacticSans" }}>
                    Technic Gray
                  </strong>
                </p>
              ) : this.state.whiteVisible ? (
                <p
                  style={{
                    fontFamily: "TacticSans",
                    marginBottom: 0,
                    textAlign: "center",
                    marginTop: "0em",
                    padding: 0,
                    color: "black",
                    marginTop: window.isPhone ? "1em" : "0em",
                    textTransform: "none",
                    fontWeight: 300,
                    fontSize: "26px",
                  }}
                >
                  <strong style={{ fontFamily: "TacticSans" }}>White</strong>
                </p>
              ) : null}
            </Col>
          </Row>
          <Row
            style={{ maxWidth: "48em", margin: "auto", marginBottom: "2em" }}
          >
            <div
              onClick={this.blackClick}
              style={{
                margin: "auto",
                marginTop: "1em",
                // backgroundColor: "#B41C1A",
                boxShadow: this.state.blackVisible
                  ? "0 0 10px 8px rgb(128 128 128 / 75%)"
                  : "",
                backgroundImage:
                  "url(" +
                  `${process.env.PUBLIC_URL}/images/chery-new/color-pro-8-max-my-23/black.webp` +
                  ")",
                backgroundSize: "100%",
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",
                width: window.isPhone ? "2.2em" : "4em",
                height: window.isPhone ? "2.2em" : "4em",
                borderRadius: "6em",
              }}
            ></div>
            <div
              onClick={this.blueClick}
              style={{
                margin: "auto",
                marginTop: "1em",
                backgroundImage:
                  "url(" +
                  `${process.env.PUBLIC_URL}/images/chery-new/color-pro-8-max-my-23/blue.webp` +
                  ")",
                backgroundSize: "100%",
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",
                boxShadow: this.state.blueVisible
                  ? "0 0 10px 8px rgb(128 128 128 / 75%)"
                  : "",
                width: window.isPhone ? "2.2em" : "4em",
                height: window.isPhone ? "2.2em" : "4em",
                borderRadius: "6em",
                cursor: "pointer",
              }}
            ></div>
            <div
              onClick={this.grayClick}
              style={{
                margin: "auto",
                marginTop: "1em",
                backgroundImage:
                  "url(" +
                  `${process.env.PUBLIC_URL}/images/chery-new/color-pro-8-max-my-23/gray.webp` +
                  ")",
                backgroundSize: "100%",
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",
                boxShadow: this.state.grayVisible
                  ? "0 0 10px 8px rgb(128 128 128 / 75%)"
                  : "",
                width: window.isPhone ? "2.2em" : "4em",
                height: window.isPhone ? "2.2em" : "4em",
                borderRadius: "6em",
                cursor: "pointer",
              }}
            ></div>
            <div
              onClick={this.greenClick}
              style={{
                margin: "auto",
                marginTop: "1em",
                backgroundImage:
                  "url(" +
                  `${process.env.PUBLIC_URL}/images/chery-new/color-pro-8-max-my-23/green.webp` +
                  ")",
                backgroundSize: "100%",
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",
                boxShadow: this.state.greenVisible
                  ? "0 0 10px 8px rgb(128 128 128 / 75%)"
                  : "",
                width: window.isPhone ? "2.2em" : "4em",
                height: window.isPhone ? "2.2em" : "4em",
                borderRadius: "6em",
                cursor: "pointer",
              }}
            ></div>
            <div
              onClick={this.purpleClick}
              style={{
                margin: "auto",

                marginTop: "1em",
                backgroundImage:
                  "url(" +
                  `${process.env.PUBLIC_URL}/images/chery-new/color-pro-8-max-my-23/purple.webp` +
                  ")",
                backgroundSize: "100%",
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",
                boxShadow: this.state.purpleVisible
                  ? "0 0 10px 8px rgb(128 128 128 / 75%)"
                  : "",
                width: window.isPhone ? "2.2em" : "4em",
                height: window.isPhone ? "2.2em" : "4em",
                borderRadius: "6em",
                cursor: "pointer",
              }}
            ></div>

            <div
              onClick={this.silverClick}
              style={{
                margin: "auto",
                marginTop: "1em",
                backgroundImage:
                  "url(" +
                  `${process.env.PUBLIC_URL}/images/chery-new/color-pro-8-max-my-23/silver.webp` +
                  ")",
                backgroundSize: "100%",
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",

                boxShadow: this.state.silverVisible
                  ? "0 0 10px 8px rgb(128 128 128 / 75%)"
                  : "",
                width: window.isPhone ? "2.2em" : "4em",
                height: window.isPhone ? "2.2em" : "4em",
                borderRadius: "6em",
                cursor: "pointer",
              }}
            ></div>
            <div
              onClick={this.technicGrayClick}
              style={{
                margin: "auto",
                marginTop: "1em",
                backgroundImage:
                  "url(" +
                  `${process.env.PUBLIC_URL}/images/chery-new/color-pro-8-max-my-23/technic-gray.webp` +
                  ")",
                backgroundSize: "100%",
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",

                boxShadow: this.state.technicGrayVisible
                  ? "0 0 10px 8px rgb(128 128 128 / 75%)"
                  : "",
                width: window.isPhone ? "2.2em" : "4em",
                height: window.isPhone ? "2.2em" : "4em",
                borderRadius: "6em",
                cursor: "pointer",
              }}
            ></div>
            <div
              onClick={this.whiteClick}
              style={{
                margin: "auto",
                marginTop: "1em",
                backgroundImage:
                  "url(" +
                  `${process.env.PUBLIC_URL}/images/chery-new/color-pro-8-max-my-23/white.webp` +
                  ")",
                backgroundSize: "100%",
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",

                boxShadow: this.state.whiteVisible
                  ? "0 0 10px 8px rgb(128 128 128 / 75%)"
                  : "",
                width: window.isPhone ? "2.2em" : "4em",
                height: window.isPhone ? "2.2em" : "4em",
                borderRadius: "6em",
                cursor: "pointer",
              }}
            ></div>
          </Row>
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="m-t-lg-30">
              <div
                className="banner-item banner-2x banner-bg-10 color-inher p-lg-30"
                style={{ backgroundColor: "white" }}
              >
                <h3 className="f-weight-300">
                  <strong
                    style={{
                      lineHeight: "1em",
                      fontFamily: "TacticSans",
                      textTransform: "none",
                    }}
                  >
                    Make Vehicle Enquiry
                  </strong>
                </h3>
                <p
                  style={{
                    margin: "auto",
                    fontFamily: "TacticSans",
                    textTransform: "none",
                    fontSize: "15px",
                  }}
                >
                  Book your test drive or viewing today.
                </p>
              </div>
              <div className="bg1-gray-2">
                <Form
                  style={{ maxWidth: "80vw", margin: "auto" }}
                  id="book-service"
                  onFinish={this.handleSubmit}
                  initialValues={{
                    remember: true,
                  }}
                  name="wrap"
                  labelCol={{ flex: "110px" }}
                  labelAlign="left"
                  labelWrap
                  wrapperCol={{ flex: 1 }}
                  colon={false}
                >
                  <div
                    className="m-b-lg-20"
                    style={{ maxWidth: "40em", margin: "auto" }}
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <Form.Item
                          name="name"
                          rules={[{ required: true, message: false }]}
                          hasFeedback
                        >
                          <StyledInput placeholder="Name" />
                        </Form.Item>
                      </div>

                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <Form.Item
                          name="number"
                          rules={[
                            {
                              //max:10,
                              //min:10,
                              required: true,
                              message: false,
                              //type: "number",
                            },
                          ]}
                          hasFeedback
                        >
                          <StyledInput placeholder="Number" />
                        </Form.Item>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <Form.Item
                          name="email"
                          rules={[
                            { required: true, message: false, type: "email" },
                          ]}
                          hasFeedback
                        >
                          <StyledInput placeholder="Email" />
                        </Form.Item>
                      </div>

                      <div className="text-center col-sm-12 col-md-12 col-lg-12">
                        <Form.Item
                          name="book"
                          rules={[{ required: false, message: false }]}
                        >
                          <Radio.Group
                            onChange={this.onChangeRadio}
                            value={this.state.valueRadio}
                          >
                            <Space
                              direction="horizontal"
                              style={{
                                display: window.isPhone ? "grid" : "",
                              }}
                            >
                              <Radio
                                style={{ color: "black" }}
                                value="Speak to a Dealer"
                              >
                                Speak to a Dealer
                              </Radio>
                              <Radio
                                style={{ color: "black" }}
                                value="Book a Test Drive"
                              >
                                Book a Test Drive
                              </Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                      <div className="col-lg-12 m-t-lg-12 text-center">
                        <Form.Item>
                          <Button
                            style={{
                              fontFamily: "Arial",
                              border: "1px solid #CD2934",
                              fontSize: "15px",
                              fontWeight: 600,
                              borderRadius: "0em",
                              backgroundColor: "#CD2934",
                              color: "white",
                              height: "55px",
                              width: "100%",
                              paddingLeft: "3em",
                              paddingRight: "3em",
                              textShadow: "none",
                              margin: "auto",
                              marginTop: "2em",
                            }}
                            type="primary"
                            htmlType="submit"
                          >
                            Send Message
                          </Button>{" "}
                        </Form.Item>{" "}
                        <p
                          style={{
                            color: "black",
                            //marginBottom: "2.5em",
                            marginTop: "0em",
                            fontSize: "13px",
                            lineHeight: "1.5em",
                          }}
                        >
                          By submitting this form, you agree to our{" "}
                          <span
                            style={{
                              fontSize: "13px",
                              textDecoration: "underline",
                            }}
                          >
                            <Link style={{ color: "black" }} to="/terms">
                              Terms of Use
                            </Link>
                          </span>{" "}
                          and{" "}
                          <span
                            style={{
                              fontSize: "13px",
                              textDecoration: "underline",
                            }}
                          >
                            <Link
                              style={{ color: "black" }}
                              to="/privacy-policy"
                            >
                              Privacy Statement
                            </Link>
                          </span>
                          .
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>{" "}
          </div>
        </section>{" "}
        <StyledModalTestDrive
          visible={this.state.visible}
          style={{ textAlign: "center" }}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/chery-white-header.png`}
            alt="Logo"
            style={{ width: "20em", margin: "auto", marginTop: "3em" }}
          />
          <h3
            style={{
              marginTop: "1em",
              color: "black",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Thank you for contacting
            <br />
            <span style={{ color: "rgb(214, 54, 41)" }}>
              CHERY KLERKSDORP.
            </span>{" "}
            <br />
            <br />
            <span style={{ color: "black ", fontSize: "17px" }}>
              Our vehicle specialist will be in touch shortly.
            </span>{" "}
            <br />
          </h3>{" "}
          <br />
        </StyledModalTestDrive>
      </div>
    );
  }
}
function mapStateToProps({ selectedStock }) {
  return { selectedStock };
}

export default connect(mapStateToProps)(withRouter(StockDetails));
