import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Select,
  message,
  Carousel,
  BackTop,
  Divider,
} from "antd";
import CountUp from "react-countup";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sample from "../videos/Chery-Tiggo-4-Pro.mp4";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
//import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import NewCar from "../images/new-car-transparent.png";
import UsedCar from "../images/used-car-transparent.png";
import DemoCar from "../images/demo-car-transparent.png";
import ThinkImg from "../images/man-thinking.png";
import CurrencyFormat from "react-currency-format";
import { connect } from "react-redux";
import {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
} from "../redux/actions";
function onChange(a, b, c) {
  console.log(a, b, c);
}
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const { Option } = Select;
const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  cssEase: "linear",
  responsive: [
    ,
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
function compareStrings(a, b) {
  a = a;
  b = b;

  return a < b ? -1 : a > b ? 1 : 0;
}
const { Meta } = Card;
const onClickBranch = ({ key }) => {
  message.info(`Website Coming Soon!`);
};
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInWishList: false,
      stock: props.stock,
      isHovered: false,
      stock: [],
      pexUnits: props.pexUnits,
      pexUnits: [],
      AllPexUnit: [],
    };
  }
  getFilters(filters) {
    this.props.stockfilters(filters);
  }

  ToggleCarWishlist(event) {
    var stock = JSON.parse(event.target.id);
    if (!this.state.isInWishList) {
      this.props.addToWishList(stock);
      this.setState({ isInWishList: true });
    } else {
      this.props.removeFromWishList(stock);
      this.setState({ isInWishList: false });
    }
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    window.updateCarousel();
    // window.reRenderRangeSlider();
    // var stock = this.props.stock;
    // fetch("https://www.vcsappcloud.com/mtgroup/api/stock/findallstockclient", {
    //   method: "GET",
    //   mode: "cors",
    //   headers: {
    //     "Content-Type": "application/json; charset=utf-8",
    //   },
    // }).then(async (res) => {
    //   const result = await res.json();
    //   this.setState({
    //     stock: result.allStock,
    //   });
    // });
    // fetch(
    //   "https://www.vcsappcloud.com/mtgroup/api/pexunit/findallpexunitclient",
    //   {
    //     method: "GET",
    //     mode: "cors",
    //     headers: {
    //       "Content-Type": "application/json; charset=utf-8",
    //     },
    //   }
    // ).then(async (res) => {
    //   const result = await res.json();
    //   this.setState({
    //     pexUnits: result.allPexUnit,
    //   });
    // });
    //if (
    //  this.props.wishlistproducts.filter(function (e) {
    //    return e._id === stock._id;
    //  }).length === 1
    //) {
    //  this.setState({ isInWishList: true });
    //}
  }
  mouseHover(event) {
    this.setState({ isHovered: true });
  }

  mouseUnHover(event) {
    this.setState({ isHovered: false });
  }

  render(props, stock) {
    //var heartStyle = {};
    //if (!this.state.isHovered) {
    //  heartStyle = {
    //    color: "black",
    //    fontWeight: "bold",
    //  };
    //}
    const duplicateCheck = [];
    return (
      <div>
        <div
          className="wrap-body-inner desktop-view"
          id="NewVehicles"
          style={{ marginTop: "-0.5em" }}
        >
          <div
            id="our-range"
            className="product product-grid product-grid-2 stock p-t-sm-35 "
            style={{ maxWidth: "100em", margin: "auto", paddingTop: "4em" }}
          >
            {/* <div style={{ textAlign: "center", marginBottom: "3em" }}>
              <h1
                style={{
                  textAlign: "left",
                  fontSize: "40px",
                  lineHeight: "0.2em",
                  marginTop: "-0.5em",
                  marginLeft: "-1em",
                  color: "#343a40",
                  fontWeight: 500,
                }}
              >
                <Divider
                  type="vertical"
                  style={{
                    height: "4em",
                    marginTop: "-2.6em",
                    borderLeft: "4px solid rgb(197, 13, 39)",
                  }}
                />{" "}
                CHERY KLERKSDORP{" "}
                <span style={{ fontWeight: 500, color: "rgb(199, 0, 32)" }}>
                  RANGE
                </span>
              </h1>
            </div> */}
            <div
              className="row"
              style={{
                backgroundImage:
                  "url(" +
                  `${process.env.PUBLIC_URL}/images/chery-new/vehicle-view-back.png` +
                  ")",
                backgroundSize: "77%",
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",
                // backgroundBlendMode: "multiply, normal"
              }}
            >
              {/* <img
                src={`${process.env.PUBLIC_URL}/images/chery-new/vehicle-view-back.png`}
                alt=''
                style={{ width: "100%", margin: "auto", position: "absolute", top: 0, zIndex: 1 }}
              /> */}
              <Slider {...settings}>
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div
                        className="hover-img "
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <Link
                          className="product-img"
                          // onClick={() => this.props.fetchSingleStock(stock)}
                          to={`/tiggo-4-pro`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/chery-new/tiggo-4-pro.png`}
                            alt=""
                            style={{
                              width: "60%",
                              margin: "auto",
                              marginTop: "8em",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "0.5em",
                      maxWidth: "90em",
                      margin: "auto",
                    }}
                  >
                    <Row>
                      <Col span={6}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/chery-new/logo-4p.png`}
                          alt=""
                          style={{ width: "90%", marginTop: "1.5em" }}
                        />
                      </Col>
                      <Col span={6}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Keyless Entry &<br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Push Button Start
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={6}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            10.25" Multimedia
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Touch Display
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={6}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Intelligent
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Voice Control
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col
                        span={24}
                        style={{
                          textAlign: "center",
                          marginTop: "1em",
                        }}
                      >
                        <Link
                          to={`/tiggo-4-pro`}
                          className="ht-btn ht-btn-default m-t-lg-10 p-l-lg-30 p-r-lg-30"
                          style={{
                            textTransform: "none",
                            borderRadius: "0.4em",
                          }}
                        >
                          EXPLORE MORE
                          <i
                            class="fa fa-angle-right"
                            aria-hidden="true"
                            style={{ marginLeft: "1em" }}
                          ></i>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div className="hover-img ">
                        <Link
                          className="product-img"
                          // onClick={() => this.props.fetchSingleStock(stock)}
                          to={`/tiggo-7-pro`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/chery-new/tiggo-7-pro.webp`}
                            alt=""
                            style={{
                              width: "60%",
                              margin: "auto",
                              marginTop: "8em",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>

                  <div
                    style={{
                      textAlign: "center",
                      padding: "0.5em",
                      maxWidth: "90em",
                      margin: "auto",
                    }}
                  >
                    <Row>
                      <Col span={6}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/chery-new/logo-7p.png`}
                          alt=""
                          style={{ width: "90%", marginTop: "1.5em" }}
                        />
                      </Col>
                      <Col span={6}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Rear View
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Camera
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={6}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Blind Spot
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Detection
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={6}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Dual Zone
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Climate Control
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col
                        span={24}
                        style={{
                          textAlign: "center",
                          marginTop: "1em",
                        }}
                      >
                        <Link
                          to={`/tiggo-7-pro`}
                          className="ht-btn ht-btn-default m-t-lg-10 p-l-lg-30 p-r-lg-30"
                          style={{
                            textTransform: "none",
                            borderRadius: "0.4em",
                          }}
                        >
                          EXPLORE MORE
                          <i
                            class="fa fa-angle-right"
                            aria-hidden="true"
                            style={{ marginLeft: "1em" }}
                          ></i>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div className="hover-img ">
                        <Link
                          className="product-img"
                          // onClick={() => this.props.fetchSingleStock(stock)}
                          to={`/tiggo-7-pro-max`} // Link Werk Nie
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/chery-new/tiggo-7-pro-max.webp`}
                            alt=""
                            style={{
                              width: "60%",
                              margin: "auto",
                              marginTop: "8em",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>

                  <div
                    style={{
                      textAlign: "center",
                      padding: "0.5em",
                      maxWidth: "90em",
                      margin: "auto",
                    }}
                  >
                    <Row>
                      <Col span={6}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/chery-new/logo-7pm.png`}
                          alt=""
                          style={{ width: "90%", marginTop: "1.5em" }}
                        />
                      </Col>
                      <Col span={6}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            1.6
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Turbo Petrol
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={6}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Full visibility with 360°
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            camera
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={6}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Autonomous
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            emergency braking
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col
                        span={24}
                        style={{
                          textAlign: "center",
                          marginTop: "1em",
                        }}
                      >
                        <Link
                          to={`/tiggo-7-pro-max`} // Link Werk Nie
                          className="ht-btn ht-btn-default m-t-lg-10 p-l-lg-30 p-r-lg-30"
                          style={{
                            textTransform: "none",
                            borderRadius: "0.4em",
                          }}
                        >
                          EXPLORE MORE
                          <i
                            class="fa fa-angle-right"
                            aria-hidden="true"
                            style={{ marginLeft: "1em" }}
                          ></i>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div className="hover-img ">
                        <Link
                          className="product-img"
                          // onClick={() => this.props.fetchSingleStock(stock)}
                          to={`/tiggo-8-pro`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/chery-new/tiggo-8-pro.webp`}
                            alt=""
                            style={{
                              width: "60%",
                              margin: "auto",
                              marginTop: "8em",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "0.5em",
                      maxWidth: "90em",
                      margin: "auto",
                    }}
                  >
                    <Row>
                      <Col span={6}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/chery-new/logo-8p.png`}
                          alt=""
                          style={{ width: "90%", marginTop: "1.5em" }}
                        />
                      </Col>
                      <Col span={6}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            360° Panoramic
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Imaging Camera
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={6}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            12.3" Digital
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Instrumentation
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={6}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Adaptive
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Cruise Control
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col
                        span={24}
                        style={{
                          textAlign: "center",
                          marginTop: "1em",
                        }}
                      >
                        <Link
                          to={`/tiggo-8-pro`}
                          className="ht-btn ht-btn-default m-t-lg-10 p-l-lg-30 p-r-lg-30"
                          style={{
                            textTransform: "none",
                            borderRadius: "0.4em",
                          }}
                        >
                          EXPLORE MORE
                          <i
                            class="fa fa-angle-right"
                            aria-hidden="true"
                            style={{ marginLeft: "1em" }}
                          ></i>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div className="hover-img ">
                        <Link
                          className="product-img"
                          // onClick={() => this.props.fetchSingleStock(stock)}
                          to={`/tiggo-8-pro-max`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/chery-new/tiggo-8-pro-max.webp`}
                            alt=""
                            style={{
                              width: "60%",
                              margin: "auto",
                              marginTop: "8em",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "0.5em",
                      maxWidth: "90em",
                      margin: "auto",
                    }}
                  >
                    <Row>
                      <Col span={6}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/chery-new/logo-8pm.png`}
                          alt=""
                          style={{ width: "90%", marginTop: "1.5em" }}
                        />
                      </Col>
                      <Col span={6}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            187kw
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Power
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={6}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Adjustable
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Driving Modes
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={6}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Panoramic Sunroof
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Powered
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col
                        span={24}
                        style={{
                          textAlign: "center",
                          marginTop: "1em",
                        }}
                      >
                        <Link
                          to={`/tiggo-8-pro-max`}
                          className="ht-btn ht-btn-default m-t-lg-10 p-l-lg-30 p-r-lg-30"
                          style={{
                            textTransform: "none",
                            borderRadius: "0.4em",
                          }}
                        >
                          EXPLORE MORE
                          <i
                            class="fa fa-angle-right"
                            aria-hidden="true"
                            style={{ marginLeft: "1em" }}
                          ></i>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div className="hover-img ">
                        <Link
                          className="product-img"
                          // onClick={() => this.props.fetchSingleStock(stock)}
                          to={`/tiggo-8-pro-max-my-23`} //Link Werk Nie
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/chery-new/tiggo-8-pro-max-my-23.webp`}
                            alt=""
                            style={{
                              width: "60%",
                              margin: "auto",
                              marginTop: "8em",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "0.5em",
                      maxWidth: "90em",
                      margin: "auto",
                    }}
                  >
                    <Row>
                      <Col span={6}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/chery-new/logo-8pm-my23.png`}
                          alt=""
                          style={{ width: "90%", marginTop: "1.5em" }}
                        />
                      </Col>
                      <Col span={6}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            24.6" Ultra Curved
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Dual Screen
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={6}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Eight-Speaker Sony
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Sound System
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={6}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Ventilated and Heated
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Front Seats
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col
                        span={24}
                        style={{
                          textAlign: "center",
                          marginTop: "1em",
                        }}
                      >
                        <Link
                          to={`/tiggo-8-pro-max-my-23`}
                          className="ht-btn ht-btn-default m-t-lg-10 p-l-lg-30 p-r-lg-30"
                          style={{
                            textTransform: "none",
                            borderRadius: "0.4em",
                          }}
                        >
                          EXPLORE MORE
                          <i
                            class="fa fa-angle-right"
                            aria-hidden="true"
                            style={{ marginLeft: "1em" }}
                          ></i>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
              </Slider>
            </div>
          </div>
          <div
            style={{
              background: "white",
              padding: 0,
              marginTop: "6em",
            }}
          >
            <div
            // style={{ backgroundColor: "rgb(242, 244, 244)" }}
            >
              <Row
                justify="center"
                style={{
                  margin: "auto",
                }}
              >
                <Col span={24}>
                  <div className="video-overlay-1"></div>
                  <Row
                    style={{
                      maxWidth: "90em",
                      margin: "auto",
                      textAlign: "left",
                      color: "white",
                      padding: "0em",
                      zIndex: 9,
                      position: "absolute",
                      left: 0,
                      right: 0,
                      marginTop: "8em",
                    }}
                  >
                    <Col
                      span={8}
                      style={{
                        padding: "3.5em",
                        paddingTop: "2.5em",
                        paddingBottom: "0.5em",
                        textAlign: "center",
                      }}
                    >
                      <h1
                        className="f-weight-500"
                        style={{ marginTop: "-0.5em", color: "white" }}
                      >
                        <CountUp end={20} delay={7} duration={5} />+
                        <br />
                      </h1>
                      <h3
                        className="f-weight-500"
                        style={{ marginTop: "-1.5em", color: "white" }}
                      >
                        <strong>Years of independent innovation</strong>
                      </h3>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        padding: "3.5em",
                        paddingTop: "2.5em",
                        paddingBottom: "0.5em",
                        textAlign: "center",
                      }}
                    >
                      <h1
                        className="f-weight-500"
                        style={{ marginTop: "-0.5em", color: "white" }}
                      >
                        <CountUp end={80} delay={7} duration={5} />+
                        <br />
                      </h1>
                      <h3
                        className="f-weight-500"
                        style={{ marginTop: "-1.5em", color: "white" }}
                      >
                        <strong>Countries/regions with export business</strong>
                      </h3>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        padding: "3.5em",
                        paddingTop: "2.5em",
                        paddingBottom: "0.5em",
                        textAlign: "center",
                      }}
                    >
                      <h1
                        className="f-weight-500"
                        style={{ marginTop: "-0.5em", color: "white" }}
                      >
                        <CountUp end={10} delay={7} duration={5} /> MILLION+
                        <br />
                      </h1>
                      <h3
                        className="f-weight-500"
                        style={{ marginTop: "-1.5em", color: "white" }}
                      >
                        <strong>Global Chery owners</strong>
                      </h3>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Link
                        to={"/ContactUs"}
                        className="ht-btn ht-btn-default m-t-lg-10 p-l-lg-30 p-r-lg-30"
                        style={{ textTransform: "none", borderRadius: "0.4em" }}
                      >
                        GET IN TOUCH{" "}
                        <i
                          class="fa fa-angle-right"
                          aria-hidden="true"
                          style={{ marginLeft: "1em" }}
                        ></i>
                      </Link>
                    </Col>
                  </Row>
                  <video
                    style={{
                      width: "100vw",
                      height: "40em",
                      objectFit: "cover",
                    }}
                    autoPlay
                    muted
                    loop
                  >
                    <source src={sample} type="video/mp4"></source>
                  </video>
                </Col>
                {/* <Col span={12}>
                </Col>
                <img
                  src={`${process.env.PUBLIC_URL}/images/mahikeng-cars-row.png`}
                  alt='logo'
                  style={{ width: "50em", position: "absolute", right: 0, marginTop: "-5em" }}
                /> */}
              </Row>
            </div>
          </div>
          <div
            id="about-chery"
            style={{
              background: "white",
              padding: 0,
              marginTop: "1em",
              marginBottom: "4em",
            }}
          >
            <div
            // style={{ backgroundColor: "rgb(242, 244, 244)" }}
            >
              <Row
                id="about-us"
                justify="center"
                style={{
                  maxWidth: "90em",
                  margin: "auto",
                  paddingTop: "8em",
                  paddingBottom: "3em",
                }}
              >
                <Col span={12}>
                  <h1
                    style={{
                      textAlign: "left",
                      fontSize: "40px",
                      lineHeight: "0.2em",
                      marginTop: "-1em",
                      marginLeft: "-0.8em",
                      color: "#343a40",
                      fontWeight: 500,
                    }}
                  >
                    <Divider
                      type="vertical"
                      style={{
                        height: "3.5em",
                        marginTop: "-2.6em",
                        borderLeft: "4px solid rgb(197, 13, 39)",
                      }}
                    />{" "}
                    INDEPENDENT{" "}
                    <span style={{ fontWeight: 500, color: "rgb(199, 0, 32)" }}>
                      INNOVATION
                    </span>
                  </h1>
                  <p
                    style={{
                      textAlign: "left",
                      paddingTop: "0em",
                      color: "black",
                      fontSize: "16px",
                      lineHeight: "1.5em",
                    }}
                  >
                    Over the past 20 years, Chery has always insisted on
                    independent innovation, established R&D centers in China,
                    Germany, United States and Brazil. It also has established a
                    global automotive R&D team of more than 5,500 people，thus
                    gradually establishing the holistic technology and product
                    R&D system. In this way, Chery has successfully created
                    product brands such as Arrizo, Tiggo with cumulative global
                    sales of more than 10 million units.
                    <br />
                    <br />
                    Chery has always focused on developing domestic and
                    international markets. Chery has continuously deepened its
                    globalization through the implementation of product
                    strategy, localization strategy and talent strategy. After
                    more than 20 years of development, Chery’s sales and
                    services network covers more than 80 countries and regions
                    and has won the trust of 9.5 million consumers worldwide.
                  </p>
                  <Link
                    to={"/ContactUs"}
                    className="ht-btn ht-btn-default m-t-lg-20 p-l-lg-30 p-r-lg-30"
                    style={{ textTransform: "none", borderRadius: "0.4em" }}
                  >
                    GET IN TOUCH{" "}
                    <i
                      class="fa fa-angle-right"
                      aria-hidden="true"
                      style={{ marginLeft: "1em" }}
                    ></i>
                  </Link>
                </Col>
                <Col span={12}></Col>
                <img
                  src={`${process.env.PUBLIC_URL}/images/mahikeng-cars-row.png`}
                  alt="logo"
                  style={{
                    width: "50em",
                    position: "absolute",
                    right: 0,
                    marginTop: "-2em",
                  }}
                />
              </Row>
            </div>
          </div>
          <div
            style={{
              height: "18em",
              width: "100vw",
              backgroundImage:
                "url(" +
                `${process.env.PUBLIC_URL}/images/background-map.png` +
                ")",

              backgroundBlendMode: "multiply, normal",
            }}
          >
            <Row
              style={{
                maxWidth: "77em",
                margin: "auto",
                textAlign: "left",
                color: "white",
                padding: "0em",
              }}
            >
              <Col
                span={24}
                style={{
                  padding: "3.5em",
                  paddingTop: "2.5em",
                  textAlign: "center",
                }}
              >
                <h2
                  className="f-weight-500"
                  style={{ marginTop: "-0.5em", color: "white" }}
                >
                  <strong>NEED MORE ASSISTANCE?</strong>
                  <br />
                </h2>
                <p
                  style={{
                    fontSize: "17px",
                    marginTop: "-1em",
                    color: "white",
                  }}
                >
                  Contact CHERY KLERKSDORP Today!
                </p>
                <Link
                  to={"/ContactUs"}
                  className="ht-btn ht-btn-default m-t-lg-10 p-l-lg-30 p-r-lg-30"
                  style={{
                    textTransform: "none",
                    borderRadius: "0.4em",
                    color: "rgb(197, 13, 39)",
                    backgroundColor: "white",
                  }}
                >
                  GET IN TOUCH{" "}
                  <i
                    class="fa fa-angle-right"
                    aria-hidden="true"
                    style={{ marginLeft: "1em" }}
                  ></i>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
        <div className="wrap-body-inner mobile-view">
          <div
            id="our-range"
            className="product product-grid product-grid-2 stock p-t-sm-35 "
            style={{ maxWidth: "100em", margin: "auto", paddingTop: "4em" }}
          >
            {/* <div style={{ textAlign: "center", marginBottom: "3em" }}>
              <h1
                style={{
                  // textAlign: "left",
                  fontSize: "25px",
                  lineHeight: "0.2em",
                  marginTop: "-0.5em",
                  marginLeft: "-1em",
                  color: "#343a40",
                  fontWeight: 500,
                }}
              >
                <Divider
                  type="vertical"
                  style={{
                    height: "4em",
                    marginTop: "-2.6em",
                    borderLeft: "4px solid rgb(197, 13, 39)",
                  }}
                />{" "}
                KLERKSDORP{" "}
                <span style={{ fontWeight: 500, color: "rgb(199, 0, 32)" }}>
                  RANGE
                </span>
              </h1>
            </div> */}
            <div
              className="row"
              style={{
                backgroundImage:
                  "url(" +
                  `${process.env.PUBLIC_URL}/images/chery-new/vehicle-view-back.png` +
                  ")",
                backgroundSize: "77%",
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",
                // backgroundBlendMode: "multiply, normal"
              }}
            >
              <Slider {...settings}>
                <Row style={{}}>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div className="hover-img ">
                        <Link
                          className="product-img"
                          // onClick={() => this.props.fetchSingleStock(stock)}
                          to={`/tiggo-4-pro`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/chery-new/tiggo-4-pro.png`}
                            alt=""
                            style={{
                              width: "90%",
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "0.5em",
                      maxWidth: "90em",
                      // margin: "auto",
                    }}
                  >
                    <Row>
                      <Col span={24}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/chery-new/logo-4p.png`}
                          alt=""
                          style={{
                            width: "80%",
                            margin: "auto",
                            marginTop: "1.5em",
                          }}
                        />
                      </Col>
                      <Col span={24}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                              marginTop: "1.2em",
                            }}
                          >
                            Keyless Entry &<br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Push Button Start
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={24}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            10.25" Multimedia
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Touch Display
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={24}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Intelligent
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Voice Control
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col
                        span={24}
                        style={{
                          textAlign: "center",
                          marginTop: "1em",
                        }}
                      >
                        <Link
                          to={`/tiggo-4-pro`}
                          className="ht-btn ht-btn-default m-t-lg-10 p-l-lg-30 p-r-lg-30"
                          style={{
                            textTransform: "none",
                            borderRadius: "0.4em",
                          }}
                        >
                          EXPLORE MORE
                          <i
                            class="fa fa-angle-right"
                            aria-hidden="true"
                            style={{ marginLeft: "1em" }}
                          ></i>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div className="hover-img ">
                        <Link
                          className="product-img"
                          // onClick={() => this.props.fetchSingleStock(stock)}
                          to={`/tiggo-7-pro`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/chery-new/tiggo-7-pro.webp`}
                            alt=""
                            style={{
                              width: "90%",
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "0.5em",
                      maxWidth: "90em",
                      margin: "auto",
                    }}
                  >
                    <Row>
                      <Col span={24}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/chery-new/logo-7p.png`}
                          alt=""
                          style={{
                            width: "80%",
                            margin: "auto",
                            marginTop: "1.5em",
                          }}
                        />
                      </Col>
                      <Col span={24}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                              marginTop: "1.5em",
                            }}
                          >
                            Rear View
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Camera
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={24}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Blind Spot
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Detection
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={24}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Dual Zone
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Climate Control
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col
                        span={24}
                        style={{
                          textAlign: "center",
                          marginTop: "1em",
                        }}
                      >
                        <Link
                          to={`/tiggo-7-pro`}
                          className="ht-btn ht-btn-default m-t-lg-10 p-l-lg-30 p-r-lg-30"
                          style={{
                            textTransform: "none",
                            borderRadius: "0.4em",
                          }}
                        >
                          EXPLORE MORE
                          <i
                            class="fa fa-angle-right"
                            aria-hidden="true"
                            style={{ marginLeft: "1em" }}
                          ></i>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div className="hover-img ">
                        <Link
                          className="product-img"
                          // onClick={() => this.props.fetchSingleStock(stock)}
                          to={`/tiggo-7-pro-max`} // Link Werk Nie
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/chery-new/tiggo-7-pro-max.webp`}
                            alt=""
                            style={{
                              width: "90%",
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "0.5em",
                      maxWidth: "90em",
                      margin: "auto",
                    }}
                  >
                    <Row>
                      <Col span={24}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/chery-new/logo-7pm.png`}
                          alt=""
                          style={{
                            width: "80%",
                            margin: "auto",
                            marginTop: "1.5em",
                          }}
                        />
                      </Col>
                      <Col span={24}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                              marginTop: "2.2em",
                            }}
                          >
                            1.6
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Turbo Petrol
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={24}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Full visibility with 360°
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            camera
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={24}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Autonomous
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            emergency braking
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col
                        span={24}
                        style={{
                          textAlign: "center",
                          marginTop: "1em",
                        }}
                      >
                        <Link
                          to={`/tiggo-7-pro-max`} // Link Werk Nie
                          className="ht-btn ht-btn-default m-t-lg-10 p-l-lg-30 p-r-lg-30"
                          style={{
                            textTransform: "none",
                            borderRadius: "0.4em",
                          }}
                        >
                          EXPLORE MORE
                          <i
                            class="fa fa-angle-right"
                            aria-hidden="true"
                            style={{ marginLeft: "1em" }}
                          ></i>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div className="hover-img ">
                        <Link
                          className="product-img"
                          // onClick={() => this.props.fetchSingleStock(stock)}
                          to={`/tiggo-8-pro`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/chery-new/tiggo-8-pro.webp`}
                            alt=""
                            style={{
                              width: "90%",
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "0.5em",
                      maxWidth: "90em",
                      margin: "auto",
                    }}
                  >
                    <Row>
                      <Col span={24}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/chery-new/logo-8p.png`}
                          alt=""
                          style={{
                            width: "80%",
                            margin: "auto",
                            marginTop: "1.5em",
                          }}
                        />
                      </Col>
                      <Col span={24}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                              marginTop: "1.7em",
                            }}
                          >
                            360° Panoramic
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Imaging Camera
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={24}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            12.3" Digital
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Instrumentation
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={24}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Adaptive
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Cruise Control
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col
                        span={24}
                        style={{
                          textAlign: "center",
                          marginTop: "1em",
                        }}
                      >
                        <Link
                          to={`/tiggo-8-pro`}
                          className="ht-btn ht-btn-default m-t-lg-10 p-l-lg-30 p-r-lg-30"
                          style={{
                            textTransform: "none",
                            borderRadius: "0.4em",
                          }}
                        >
                          EXPLORE MORE
                          <i
                            class="fa fa-angle-right"
                            aria-hidden="true"
                            style={{ marginLeft: "1em" }}
                          ></i>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div className="hover-img ">
                        <Link
                          className="product-img"
                          // onClick={() => this.props.fetchSingleStock(stock)}
                          to={`/tiggo-8-pro-max`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/chery-new/tiggo-8-pro-max.webp`}
                            alt=""
                            style={{
                              width: "90%",
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "0.5em",
                      maxWidth: "90em",
                      margin: "auto",
                    }}
                  >
                    <Row>
                      <Col span={24}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/chery-new/logo-8pm.png`}
                          alt=""
                          style={{
                            width: "80%",
                            margin: "auto",
                            marginTop: "1.5em",
                          }}
                        />
                      </Col>
                      <Col span={24}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                              marginTop: "2.2em",
                            }}
                          >
                            187kw
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Power
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={24}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Adjustable
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Driving Modes
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={24}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Panoramic Sunroof
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Powered
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col
                        span={24}
                        style={{
                          textAlign: "center",
                          marginTop: "1em",
                        }}
                      >
                        <Link
                          to={`/tiggo-8-pro-max`}
                          className="ht-btn ht-btn-default m-t-lg-10 p-l-lg-30 p-r-lg-30"
                          style={{
                            textTransform: "none",
                            borderRadius: "0.4em",
                          }}
                        >
                          EXPLORE MORE
                          <i
                            class="fa fa-angle-right"
                            aria-hidden="true"
                            style={{ marginLeft: "1em" }}
                          ></i>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
                <Row>
                  <Fade bottom delay={100} casacde>
                    <Col span={24}>
                      <div className="hover-img ">
                        <Link
                          className="product-img"
                          // onClick={() => this.props.fetchSingleStock(stock)}
                          to={`/tiggo-8-pro-max-my-23`} //Link Werk Nie
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/chery-new/tiggo-8-pro-max-my-23.webp`}
                            alt=""
                            style={{
                              width: "90%",
                              margin: "auto",
                              marginTop: "2em",
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Fade>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "0.5em",
                      maxWidth: "90em",
                      margin: "auto",
                    }}
                  >
                    <Row>
                      <Col span={24}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/chery-new/logo-8pm-my23.png`}
                          alt=""
                          style={{
                            width: "80%",
                            margin: "auto",
                            marginTop: "1.5em",
                          }}
                        />
                      </Col>
                      <Col span={24}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                              marginTop: "1.2em",
                            }}
                          >
                            24.6" Ultra Curved
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Dual Screen
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={24}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Eight-Speaker Sony
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Sound System
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col span={24}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSansBd1",
                              display: "block",
                              color: "#c50d27",
                            }}
                          >
                            Ventilated and Heated
                            <br />
                          </span>
                          <span
                            style={{
                              fontSize: "18px",
                              textTransform: "none",
                              fontFamily: "TacticSans",
                              display: "block",
                              color: "black",
                            }}
                          >
                            Front Seats
                            <br />
                          </span>
                        </h4>
                      </Col>
                      <Col
                        span={24}
                        style={{
                          textAlign: "center",
                          marginTop: "1em",
                        }}
                      >
                        <Link
                          to={`/tiggo-8-pro-max-my-23`}
                          className="ht-btn ht-btn-default m-t-lg-10 p-l-lg-30 p-r-lg-30"
                          style={{
                            textTransform: "none",
                            borderRadius: "0.4em",
                          }}
                        >
                          EXPLORE MORE
                          <i
                            class="fa fa-angle-right"
                            aria-hidden="true"
                            style={{ marginLeft: "1em" }}
                          ></i>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Row>
              </Slider>
            </div>
          </div>
          <div
            style={{
              background: "white",
              padding: 0,
              marginTop: "4em",
            }}
          >
            <div
            // style={{ backgroundColor: "rgb(242, 244, 244)" }}
            >
              <Row
                justify="center"
                style={{
                  margin: "auto",
                }}
              >
                <Col span={24}>
                  <div className="video-overlay-1"></div>
                  <Row
                    style={{
                      maxWidth: "90em",
                      margin: "auto",
                      textAlign: "left",
                      color: "white",
                      padding: "0em",
                      zIndex: 9,
                      position: "absolute",
                      left: 0,
                      right: 0,
                      marginTop: "4em",
                    }}
                  >
                    <Col
                      span={24}
                      style={{
                        // padding: "3.5em",
                        // paddingTop: "2.5em",
                        paddingBottom: "0.5em",
                        textAlign: "center",
                      }}
                    >
                      <h1
                        className="f-weight-500"
                        style={{ marginTop: "-0.5em", color: "white" }}
                      >
                        <CountUp end={20} delay={7} duration={5} />+
                        <br />
                      </h1>
                      <h3
                        className="f-weight-500"
                        style={{ marginTop: "-1.5em", color: "white" }}
                      >
                        <strong>Years of independent innovation</strong>
                      </h3>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        // padding: "3.5em",
                        // paddingTop: "2.5em",
                        paddingBottom: "0.5em",
                        textAlign: "center",
                      }}
                    >
                      <h1
                        className="f-weight-500"
                        style={{ marginTop: "-0.5em", color: "white" }}
                      >
                        <CountUp end={80} delay={7} duration={5} />+
                        <br />
                      </h1>
                      <h3
                        className="f-weight-500"
                        style={{ marginTop: "-1.5em", color: "white" }}
                      >
                        <strong>Countries/regions with export business</strong>
                      </h3>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        // padding: "3.5em",
                        // paddingTop: "2.5em",
                        paddingBottom: "0.5em",
                        textAlign: "center",
                      }}
                    >
                      <h1
                        className="f-weight-500"
                        style={{ marginTop: "-0em", color: "white" }}
                      >
                        <CountUp end={10} delay={7} duration={5} /> Million+
                        <br />
                      </h1>
                      <h3
                        className="f-weight-500"
                        style={{ marginTop: "-1.5em", color: "white" }}
                      >
                        <strong>Global Chery owners</strong>
                      </h3>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Link
                        to={"/ContactUs"}
                        className="ht-btn ht-btn-default m-t-lg-20 p-l-lg-30 p-r-lg-30"
                        style={{ textTransform: "none", borderRadius: "0.4em" }}
                      >
                        GET IN TOUCH{" "}
                        <i
                          class="fa fa-angle-right"
                          aria-hidden="true"
                          style={{ marginLeft: "1em" }}
                        ></i>
                      </Link>
                    </Col>
                  </Row>
                  <video
                    style={{
                      width: "100vw",
                      height: "55em",
                      objectFit: "cover",
                    }}
                    autoPlay
                    muted
                    loop
                  >
                    <source src={sample} type="video/mp4"></source>
                  </video>
                </Col>
                {/* <Col span={12}>
    </Col>
    <img
      src={`${process.env.PUBLIC_URL}/images/mahikeng-cars-row.png`}
      alt='logo'
      style={{ width: "50em", position: "absolute", right: 0, marginTop: "-5em" }}
    /> */}
              </Row>
            </div>
          </div>
          <div
            id="about-chery"
            style={{
              background: "white",
              padding: 0,
              marginTop: "1em",
              marginBottom: "4em",
            }}
          >
            <div
            // style={{ backgroundColor: "rgb(242, 244, 244)" }}
            >
              <Row
                id="about-us-mobile"
                justify="center"
                style={{
                  maxWidth: "90em",
                  margin: "auto",
                  paddingTop: "4em",
                  paddingBottom: "3em",
                }}
              >
                <Col span={24} style={{ textAlign: "center" }}>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: "23px",
                      lineHeight: "0.2em",
                      marginTop: "-1em",
                      marginLeft: "-0.8em",
                      color: "#343a40",
                      fontWeight: 500,
                    }}
                  >
                    <Divider
                      type="vertical"
                      style={{
                        height: "3.5em",
                        marginTop: "-2.6em",
                        borderLeft: "4px solid rgb(197, 13, 39)",
                      }}
                    />{" "}
                    INDEPENDENT{" "}
                    <span style={{ fontWeight: 500, color: "rgb(199, 0, 32)" }}>
                      INNOVATION
                    </span>
                  </h1>
                  <p
                    style={{
                      textAlign: "center",

                      padding: "1em",
                      color: "black",
                      fontSize: "16px",
                      lineHeight: "1.5em",
                    }}
                  >
                    Over the past 20 years, Chery has always insisted on
                    independent innovation, established R&D centers in China,
                    Germany, United States and Brazil. It also has established a
                    global automotive R&D team of more than 5,500 people，thus
                    gradually establishing the holistic technology and product
                    R&D system. In this way, Chery has successfully created
                    product brands such as Arrizo, Tiggo with cumulative global
                    sales of more than 10 million units.
                    <br />
                    <br />
                    Chery has always focused on developing domestic and
                    international markets. Chery has continuously deepened its
                    globalization through the implementation of product
                    strategy, localization strategy and talent strategy. After
                    more than 20 years of development, Chery’s sales and
                    services network covers more than 80 countries and regions
                    and has won the trust of 9.5 million consumers worldwide.
                  </p>
                  <Link
                    to={"/ContactUs"}
                    className="ht-btn ht-btn-default m-t-lg-20 p-l-lg-30 p-r-lg-30"
                    style={{ textTransform: "none", borderRadius: "0.4em" }}
                  >
                    GET IN TOUCH{" "}
                    <i
                      class="fa fa-angle-right"
                      aria-hidden="true"
                      style={{ marginLeft: "1em" }}
                    ></i>
                  </Link>
                </Col>
                {/* <Col span={24}>
                </Col> */}
                {/* <img
                  src={`${process.env.PUBLIC_URL}/images/mahikeng-cars-row.png`}
                  alt='logo'
                  style={{ width: "50em", position: "absolute", right: 0, marginTop: "-2em" }}
                /> */}
              </Row>
            </div>
          </div>
          <div
            style={{
              height: "23em",
              width: "100vw",
              backgroundImage:
                "url(" +
                `${process.env.PUBLIC_URL}/images/background-map.png` +
                ")",

              backgroundBlendMode: "multiply, normal",
            }}
          >
            <Row
              style={{
                maxWidth: "77em",
                margin: "auto",
                textAlign: "left",
                color: "white",
                padding: "0em",
              }}
            >
              <Col
                span={24}
                style={{
                  padding: "3.5em",
                  paddingTop: "2.5em",
                  textAlign: "center",
                }}
              >
                <h2
                  className="f-weight-500"
                  style={{ marginTop: "-0.5em", color: "white" }}
                >
                  <strong style={{ lineHeight: "1.2em" }}>
                    NEED MORE ASSISTANCE?
                  </strong>
                  <br />
                </h2>
                <p
                  style={{
                    fontSize: "17px",
                    marginTop: "-1em",
                    color: "white",
                  }}
                >
                  Contact CHERY KLERKSDORP Today!
                </p>
                <Link
                  to={"/ContactUs"}
                  className="ht-btn ht-btn-default m-t-lg-10 p-l-lg-30 p-r-lg-30"
                  style={{
                    textTransform: "none",
                    borderRadius: "0.4em",
                    color: "rgb(197, 13, 39)",
                    backgroundColor: "white",
                  }}
                >
                  GET IN TOUCH{" "}
                  <i
                    class="fa fa-angle-right"
                    aria-hidden="true"
                    style={{ marginLeft: "1em" }}
                  ></i>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
        <BackTop />
      </div>
    );
  }
}

function mapStateToProps({ wishlistproducts }) {
  return { wishlistproducts };
}

export default connect(mapStateToProps, {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
})(Home);
