import React, { useState, Component } from "react";
import styled from "styled-components";
import moment from "moment";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  Modal,
  Select,
  DatePicker,
} from "antd";
import { Link } from "react-router-dom";
//import { Modal as SuccessModal } from "react-responsive-modal";
//import "react-responsive-modal/styles.css";
import ImgCrop from "antd-img-crop";
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}

const { Option, OptGroup } = Select;
const { TextArea } = Input;

const StyledInput = styled(Input)`

  padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid lightgrey !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;

    box-shadow:none !important;
}
  :active{
    border-color: red !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    
     box-shadow:none !important;
   
  }
  }
`;
const StyledDatePicker = styled(DatePicker)`

  padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid lightgrey !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;

    box-shadow:none !important;
}
  :active{
    border-color: red !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    
     box-shadow:none !important;
   
  }
  }
`;
const StyledTextArea = styled(TextArea)`
padding-left:0.4em !important;
  box-shadow:none !important;
background-color: white!important;
background-image: none;
border: none;
border: 0px solid black !important;
border: 1px solid lightgrey !important;
border-radius: 0px;
color: black!important;
//box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
.ant-input:focus, .ant-input-focused {
  border-color:  transparent !important;
  -webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  box-shadow: none !important;
  border-right-width: 1px;
  outline: 0;
}
.ant-input {
border-color: transparent !important;
-webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
box-shadow: none !important;
border-right-width: 1px;
outline: 0;
}
:placeholder {
  color: lightgrey !important;
}
:hover {
  border-color: red !important;

  box-shadow:none !important;
}
:active{
  border-color: red !important;
 
   box-shadow:none !important;
 
}
:focus{
  border-color: red !important;
  
   box-shadow:none !important;
 
}
}
`;
const StyledSelect = styled(Select)`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid #e9e9e9; */
    height: 45px !important;
    padding: 5px 11px !important;
    border: 1px solid white !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-multiple .ant-select-selector {
    height: 45px !important;
  }
  border: 1px solid lightgrey !important;
  border-radius: 0em !important;
  height: 45px !important;
  box-shadow: none !important;
  :hover {
    border-color: red !important;

    box-shadow: none !important;
  }
  :active {
    border-color: red !important;

    box-shadow: none !important;
  }
  :focus {
    border-color: red !important;

    box-shadow: none !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: red !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }
`;
const dateFormat = "YYYY/MM/DD";
const customFormat = (value) => `custom format: ${value.format(dateFormat)}`;
const onChange = (date, dateString) => {
  console.log(date, dateString);
};

class BookService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBranch: "",
      klerksdorpMails: "leads@megatheron.co.za",
      klerksdorpMail: "service@megatheron.co.za",
      potchefstroomMail: "potchservice@megatheron.co.za",
      potchefstroomMail1: "potchservice@megatheron.co.za",
      mahikengMail: "service1@megatheron.co.za",
      mahikengMail2: "service1@megatheron.co.za",
      mahikengMail3: "service1@megatheron.co.za",

      visible: false,
    };

    this.onFinish = this.onFinish.bind(this);
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
  }
  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
    window.location.assign("./");
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  handleSubmit = (values) => {
    this.onFinish(values);
  };
  onFinish = (values) => {
    const MSG = {
      to: values.branch,
      from: "info@mtgroup.co.za",
      //to: [  //"leads@megatheron.co.za", "monique@moniqs-interiors.com"],
      subject: "New car sale request",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/z5SfxC9/mtgroup.png" width="300" height="85" style="display: block; border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Car Sale Request</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new car sale request from <br /> Chery website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br />
                          <strong> Year </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.year
        }<br />
                          <strong> Make </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.make
        }<br />
                          <strong> Model </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.model
        }<br />
                       
                          <strong> Derivative</strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.derivative
        }<br />
                          <strong> Details </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.details
        }<br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.name
        }<br />
                          <strong> Number </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.number
        }<br />
                          <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.email
        }<br />
                          <strong> Date </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.date.format(
          dateFormat
        )}
                             <br /><br />
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2024@CHERY</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        visible: true,
        submitDisabled: true,
        year: "",
        make: "",
        model: "",
        derivative: "",
        name: "",
        number: "",
        email: "",
        branch: "",
        date: "",
        details: "",
      });
    });
  };
  render() {
    return (
      <div>
        {window.isPhone != true ? (
          <div
            className='wrap-body-inner'
            style={{ backgroundColor: "transparent" }}
          >
            {/*<div className="container-custom">*/}
            <div className='row m-b-lg-80'>
              <div className='col-sm-5 col-md-5 col-lg-5'>
                <div className='banner-item-home banner-2x-home no-bg color-inher-home'>
                  <h1 style={{}} className='f-weight-400 f-20'>
                    GREAT ATTENTION TO DETAIL
                  </h1>
                  <h1 className='f-weight-600 f-40'>
                    The industry's top retailer <br />
                  </h1>
                  <h1 className='f-weight-400 f-40'>IN CAR PARTS & SERVICE</h1>
                  <p>
                    Mega Theron Group makes it easy to get the parts & Service
                    your car deserves, at the best prices possible.
                  </p>
                  <a
                    href='#book-service'
                    className='ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30'
                    style={{ textTransform: "none" }}
                  >
                    Book a Service
                  </a>{" "}
                  <Link
                    to='/ContactUs'
                    className='ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30'
                    style={{
                      textTransform: "none",
                      marginLeft: "1em",
                      backgroundColor: "#201a20",
                    }}
                  >
                    Get in Touch
                  </Link>
                  {/*</div>*/}
                </div>
              </div>
              <div className='col-sm-2 col-md-2 col-lg-2'></div>
              <div className='col-sm-5 col-md-5 col-lg-5'>
                <img
                  style={{
                    width: "37em",
                    right: 0,
                    zIndex: 0,
                  }}
                  src={`${process.env.PUBLIC_URL}/images/service-banner.png`}
                  alt='logo'
                />
              </div>
            </div>
            {/*<div id="sellYourCar" className="hidden-xs">
          <div className="row">
            <div className="col-lg-6">
              <ul className="ht-breadcrumb pull-left">
                <li className="home-act">
                  <Link to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li className="home-act">
                  <Link to="/stock">All Cars</Link>
                </li>
                <li className="active">Sell a car</li>
              </ul>
            </div>
          </div>
        </div>*/}
            <Form
              style={{ maxWidth: "80vw", margin: "auto" }}
              id='book-service'
              onFinish={this.handleSubmit}
              initialValues={{
                remember: true,
              }}
              name='wrap'
              labelCol={{ flex: "110px" }}
              labelAlign='left'
              labelWrap
              wrapperCol={{ flex: 1 }}
              colon={false}
            >
              <section className='m-t-lg-30 m-t-xs-0 m-b-lg-50'>
                <div>
                  <div className='row'>
                    <div className='col-md-12 col-lg-12'>
                      <div
                        className='bg-whitef5 bg1-gray-15 p-lg-30 p-xs-15'
                        style={{
                          boxShadow: "2px 2px 10px 2px lightgrey",
                          borderRadius: "1.5em",
                          backgroundColor: "white",
                        }}
                      >
                        <div className='m-b-lg-10'>
                          <div className='heading-1 text-align-lg-center'>
                            <h3>Book Vehicle Service</h3>
                          </div>
                          <p className='m-b-lg-20 text-align-lg-center'>
                            Fill in the form below and one of our stock
                            specialists wil get in touch
                          </p>
                        </div>

                        <div className='m-b-lg-20'>
                          <div className='heading-1'>
                            <h3>Car Details </h3>
                          </div>

                          <div className='row'>
                            <div className='col-sm-6 col-md-4 col-lg-3'>
                              <Form.Item
                                name='year'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Year' />
                              </Form.Item>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3'>
                              <Form.Item
                                name='make'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Make' />
                              </Form.Item>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3'>
                              <Form.Item
                                name='model'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Model' />
                              </Form.Item>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-3'>
                              <Form.Item
                                name='derivative'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Derivative' />
                              </Form.Item>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <Form.Item
                                name='details'
                                rules={[{ required: false, message: false }]}
                                hasFeedback
                              >
                                <StyledTextArea
                                  placeholder='Anything we need to know about your stock?'
                                  showCount
                                  maxLength={100}
                                  style={{
                                    height: 120,
                                    border: "1px solid black",
                                  }}
                                //onChange={onChange}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>

                        <div className='m-b-lg-20'>
                          <div className='heading-1'>
                            <h3>Contact Details</h3>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4 col-md-4 col-lg-4'>
                              <Form.Item
                                name='name'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Name' />
                              </Form.Item>
                            </div>

                            <div className='col-sm-4 col-md-4 col-lg-4'>
                              <Form.Item
                                name='number'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Number' />
                              </Form.Item>
                            </div>
                            <div className='col-sm-4 col-md-4 col-lg-4'>
                              <Form.Item
                                name='email'
                                rules={[
                                  {
                                    required: true,
                                    message: false,
                                    type: "email",
                                  },
                                ]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Email' />
                              </Form.Item>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                              <Form.Item
                                name='branch'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledSelect
                                  bordered={false}
                                  style={{
                                    border: "1px solid black",
                                    borderRadius: "0em",
                                    height: 50,
                                  }}
                                  placeholder='Branch'
                                //onChange={this.setCarEngineCapacity}
                                >
                                  <OptGroup label='Klerksdorp'>
                                    <Option
                                      key={0}
                                      value={this.state.klerksdorpMail}
                                    >
                                      Chery (Klerksdorp)
                                    </Option>
                                  </OptGroup>
                                  <OptGroup label='Potchefstroom'>
                                    <Option
                                      key={1}
                                      value={this.state.potchefstroomMail}
                                    >
                                      GWM (Potchefstroom)
                                    </Option>
                                    <Option
                                      key={2}
                                      value={this.state.potchefstroomMail1}
                                    >
                                      Haval (Potchefstroom)
                                    </Option>
                                  </OptGroup>
                                  <OptGroup label='Mahikeng'>
                                    <Option
                                      key={3}
                                      value={this.state.mahikengMail}
                                    >
                                      Kia (Mahikeng)
                                    </Option>
                                    <Option
                                      key={4}
                                      value={this.state.mahikengMail1}
                                    >
                                      Mahindra (Mahikeng)
                                    </Option>
                                    <Option
                                      key={5}
                                      value={this.state.mahikengMail2}
                                    >
                                      GWM (Mahikeng)
                                    </Option>
                                    <Option
                                      key={6}
                                      value={this.state.mahikengMail3}
                                    >
                                      Haval (Mahikeng)
                                    </Option>
                                  </OptGroup>
                                </StyledSelect>
                              </Form.Item>
                            </div >
                            <div className='col-sm-4 col-md-4 col-lg-4'>
                              <Form.Item
                                name='date'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledDatePicker
                                  //defaultValue={moment(moment(), dateFormat)}
                                  format={dateFormat}
                                  style={{
                                    height: 50,
                                    border: "1px solid black",
                                    width: "100%",
                                  }}
                                />
                              </Form.Item>
                            </div >

                            <div className='col-lg-12 m-t-lg-12 text-center'>
                              <Form.Item>
                                <Button
                                  style={{

                                    border: "1px solid black",
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    borderRadius: "0em",
                                    backgroundColor: "black",
                                    color: "white",
                                    height: "55px",

                                    paddingLeft: "3em",
                                    paddingRight: "3em",
                                    textShadow: "none",
                                    margin: "auto",
                                    marginTop: "2em",
                                  }}
                                  type='primary'
                                  htmlType='submit'
                                >
                                  Submit
                                </Button>{" "}
                              </Form.Item>{" "}
                              <p
                                style={{
                                  color: "black",
                                  //marginBottom: "2.5em",
                                  marginTop: "0em",
                                  fontSize: "13px",
                                  lineHeight: "1.5em",
                                }}
                              >
                                By submitting this form, you agree to our
                                <span
                                  style={{
                                    fontSize: "13px",
                                    textDecoration: "underline",
                                  }}
                                >
                                  <Link style={{ color: "black" }} to='/terms'>
                                    Terms of Use
                                  </Link>
                                </span>{" "}
                                and{" "}
                                <span
                                  style={{
                                    fontSize: "13px",
                                    textDecoration: "underline",
                                  }}
                                >
                                  <Link
                                    style={{ color: "black" }}
                                    to='/privacy-policy'
                                  >
                                    Privacy Statement
                                  </Link>
                                </span>
                                .
                              </p>{" "}
                            </div>
                          </div >
                        </div >
                      </div >
                    </div >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/sell-img-left.png`}
                      alt='logo'
                      style={{
                        marginTop: "0em",
                        zIndex: -1,
                        left: 0,
                        position: "absolute",
                        width: "40vw",
                      }}
                    />
                    <img
                      src={`${process.env.PUBLIC_URL}/images/sell-img-right.png`}
                      alt='logo'
                      style={{
                        zIndex: -1,
                        right: 0,
                        marginTop: "0em",
                        position: "absolute",
                        width: "40vw",
                      }}
                    />
                  </div >
                </div >
              </section >
            </Form >
            {/*<SuccessModal
            open={this.state.successModal}
            onClose={this.handleCloseSuccessModal}
            center
          >
            <img
                src={`${process.env.PUBLIC_URL}/images/logo.png`}
              alt='Logo'
              style={{ width: "65%" }}
            />
            <h3
              style={{
                marginTop: "1em",
                color: "black",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              Thank you for contacting
              <br />
              <span style={{ color: "rgb(214, 54, 41)" }}>
                CHERY KLERKSDORP.
              </span>{" "}
              <br />
              <br />
              <span style={{ color: "black ", fontSize: "17px" }}>
                Our vehicle specialist will be in touch shortly.
              </span>{" "}
              <br />
            </h3>{" "}
            <Button
              onClick={this.handleCloseSuccessModal}
              style={{
                margin: "auto",
                height: "2.9em",
                
                fontWeight: 500,
                borderRadius: "5em",
                backgroundColor: "#D63629",
                border: "none",
                marginBottom: "1em",
                paddingLeft: "3em",
                paddingRight: "3em",
                marginRight: "0em",
                textShadow: "none",
              }}
              type='primary'
            >
              Close
            </Button>{" "}
          </SuccessModal>*/}
          </div >
        ) : (
          <div
            className='wrap-body-inner'
            style={{ backgroundColor: "transparent" }}
          >
            {/*<div className="container-custom">*/}
            <div className='row m-b-lg-80 m-t-lg-40'>
              <div className='col-sm-12 col-md-12 col-lg-12'>
                <div className='text-center'>
                  <h1
                    className='f-weight-600 f-40'
                    style={{ marginBottom: "0em" }}
                  >
                    The industry's top retailer
                  </h1>
                  <h1
                    style={{
                      color: "rgb(197, 13, 39)",
                      textTransform: "none",
                      marginBottom: "0em",
                      marginTop: "-0.3em",
                      paddingTop: "0em",
                    }}
                    className='f-weight-400 f-30'
                  >
                    IN CAR PARTS & SERVICE
                  </h1>

                  <p>
                    Mega Theron Group makes it easy to get the parts & Service
                    your car deserves, at the best prices possible.
                  </p>
                  <a
                    href='#book-service-mobile'
                    className='ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30'
                    style={{ textTransform: "none" }}
                  >
                    Bool a Service
                  </a>
                  <Link
                    to='/ContactUs'
                    className='ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30'
                    style={{
                      textTransform: "none",
                      marginLeft: "1em",
                      backgroundColor: "#201a20",
                    }}
                  >
                    Get in Touch
                  </Link>
                  {/*</div>*/}
                </div>
              </div>
            </div>
            {/*<div id="sellYourCar" className="hidden-xs">
          <div className="row">
            <div className="col-lg-6">
              <ul className="ht-breadcrumb pull-left">
                <li className="home-act">
                  <Link to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li className="home-act">
                  <Link to="/stock">All Cars</Link>
                </li>
                <li className="active">Sell a car</li>
              </ul>
            </div>
          </div>
        </div>*/}
            <Form
              id='book-service-mobile'
              onFinish={this.handleSubmit}
              style={{ marginBottom: "-5em" }}
              initialValues={{
                remember: true,
              }}
              name='wrap'
              labelCol={{ flex: "110px" }}
              labelAlign='left'
              labelWrap
              wrapperCol={{ flex: 1 }}
              colon={false}
            >
              <section className='m-t-lg-30 m-t-xs-0 m-b-lg-50'>
                <div>
                  <div className='row'>
                    <div className='col-md-12 col-lg-12'>
                      <div
                        className='bg-whitef5 bg1-gray-15 p-lg-30 p-xs-15'
                        style={
                          {
                            //boxShadow: "2px 2px 10px 2px lightgrey",
                            //borderRadius: "1.5em",
                            //backgroundColor: "white",
                          }
                        }
                      >
                        <div className='m-b-lg-10'>
                          <div className='heading-1 text-align-lg-center'>
                            <h3>Book Vehicle Service</h3>
                          </div>
                          <p className='m-b-lg-20 text-align-lg-center'>
                            Fill in the form below and one of our stock
                            specialists wil get in touch
                          </p>
                        </div>

                        <div className='m-b-lg-20'>
                          <div className='heading-1'>
                            <h3>Car Details </h3>
                          </div>

                          <div className='row'>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                              <Form.Item
                                name='make'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Make' />
                              </Form.Item>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                              <Form.Item
                                name='model'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Model' />
                              </Form.Item>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                              <Form.Item
                                name='year'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Year' />
                              </Form.Item>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <Form.Item
                                name='details'
                                rules={[{ required: false }]}
                              >
                                <StyledTextArea
                                  placeholder='Anything we need to know about your stock?'
                                  showCount
                                  maxLength={100}
                                  style={{
                                    height: 120,
                                    border: "1px solid black",
                                  }}
                                //onChange={onChange}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>

                        <div className='m-b-lg-20'>
                          <div className='heading-1'>
                            <h3>Contact Details</h3>
                          </div>

                          <div className='row'>
                            <div className='col-sm-4 col-md-4 col-lg-4'>
                              <Form.Item
                                name='name'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Name' />
                              </Form.Item>
                            </div>

                            <div className='col-sm-4 col-md-4 col-lg-4'>
                              <Form.Item
                                name='number'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Number' />
                              </Form.Item>
                            </div>
                            <div className='col-sm-4 col-md-4 col-lg-4'>
                              <Form.Item
                                name='email'
                                rules={[
                                  {
                                    required: true,
                                    message: false,
                                    type: "email",
                                  },
                                ]}
                                hasFeedback
                              >
                                <StyledInput placeholder='Email' />
                              </Form.Item>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-4'>
                              <Form.Item
                                name='branch'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <StyledSelect
                                  bordered={false}
                                  style={{
                                    border: "1px solid black",
                                    borderRadius: "0em",
                                    height: 50,
                                  }}
                                  placeholder='Branch'
                                //onChange={this.setCarEngineCapacity}
                                >
                                  <OptGroup label='Klerksdorp'>
                                    <Option
                                      key={0}
                                      value={this.state.klerksdorpMail}
                                    >
                                      Chery (Klerksdorp)
                                    </Option>
                                  </OptGroup>
                                  <OptGroup label='Potchefstroom'>
                                    <Option
                                      key={1}
                                      value={this.state.potchefstroomMail}
                                    >
                                      GWM (Potchefstroom)
                                    </Option>
                                    <Option
                                      key={2}
                                      value={this.state.potchefstroomMail1}
                                    >
                                      Haval (Potchefstroom)
                                    </Option>
                                  </OptGroup>
                                  <OptGroup label='Mahikeng'>
                                    <Option
                                      key={3}
                                      value={this.state.mahikengMail}
                                    >
                                      Kia (Mahikeng)
                                    </Option>
                                    <Option
                                      key={4}
                                      value={this.state.mahikengMail1}
                                    >
                                      Mahindra (Mahikeng)
                                    </Option>
                                    <Option
                                      key={5}
                                      value={this.state.mahikengMail2}
                                    >
                                      GWM (Mahikeng)
                                    </Option>
                                    <Option
                                      key={6}
                                      value={this.state.mahikengMail3}
                                    >
                                      Haval (Mahikeng)
                                    </Option>
                                  </OptGroup>
                                </StyledSelect>
                              </Form.Item>
                            </div>
                            <div className='col-sm-4 col-md-4 col-lg-4'>
                              <Form.Item
                                name='date'
                                rules={[{ required: true, message: false }]}
                                hasFeedback
                              >
                                <DatePicker
                                  //defaultValue={moment(moment(), dateFormat)}
                                  format={dateFormat}
                                  style={{
                                    height: 50,
                                    border: "1px solid black",
                                    width: "100%",
                                  }}
                                />
                              </Form.Item>
                            </div >

                            <div className='col-lg-12 m-t-lg-12 text-center'>
                              <Form.Item>
                                <Button
                                  style={{

                                    border: "1px solid black",
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    borderRadius: "0em",
                                    backgroundColor: "black",
                                    color: "white",
                                    height: "55px",

                                    paddingLeft: "3em",
                                    paddingRight: "3em",
                                    textShadow: "none",
                                    margin: "auto",
                                    marginTop: "2em",
                                  }}
                                  type='primary'
                                  htmlType='submit'
                                >
                                  Submit
                                </Button>{" "}
                              </Form.Item>{" "}
                              <p
                                style={{
                                  color: "black",
                                  //marginBottom: "2.5em",
                                  marginTop: "0em",
                                  fontSize: "13px",
                                  lineHeight: "1.5em",
                                }}
                              >
                                By submitting this form, you agree to our
                                <span
                                  style={{
                                    fontSize: "13px",
                                    textDecoration: "underline",
                                  }}
                                >
                                  <Link style={{ color: "black" }} to='/terms'>
                                    Terms of Use
                                  </Link>
                                </span>{" "}
                                and{" "}
                                <span
                                  style={{
                                    fontSize: "13px",
                                    textDecoration: "underline",
                                  }}
                                >
                                  <Link
                                    style={{ color: "black" }}
                                    to='/privacy-policy'
                                  >
                                    Privacy Statement
                                  </Link>
                                </span>
                                .
                              </p>{" "}
                            </div>
                          </div >
                        </div >
                      </div >
                    </div >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/sell-img-left.png`}
                      alt='logo'
                      style={{
                        marginTop: "10em",
                        zIndex: -1,
                        left: 0,
                        position: "absolute",
                        width: "40vw",
                      }}
                    />
                    <img
                      src={`${process.env.PUBLIC_URL}/images/sell-img-right.png`}
                      alt='logo'
                      style={{
                        zIndex: -1,
                        right: 0,
                        marginTop: "10em",
                        position: "absolute",
                        width: "40vw",
                      }}
                    />
                  </div >
                </div >
              </section >
            </Form >
            {/*<SuccessModal
            open={this.state.successModal}
            onClose={this.handleCloseSuccessModal}
            center
          >
            <img
                src={`${process.env.PUBLIC_URL}/images/logo.png`}
              alt='Logo'
              style={{ width: "65%" }}
            />
            <h3
              style={{
                marginTop: "1em",
                color: "black",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              Thank you for contacting
              <br />
              <span style={{ color: "rgb(214, 54, 41)" }}>
                CHERY KLERKSDORP.
              </span>{" "}
              <br />
              <br />
              <span style={{ color: "black ", fontSize: "17px" }}>
                Our vehicle specialist will be in touch shortly.
              </span>{" "}
              <br />
            </h3>{" "}
            <Button
              onClick={this.handleCloseSuccessModal}
              style={{
                margin: "auto",
                height: "2.9em",
                
                fontWeight: 500,
                borderRadius: "5em",
                backgroundColor: "#D63629",
                border: "none",
                marginBottom: "1em",
                paddingLeft: "3em",
                paddingRight: "3em",
                marginRight: "0em",
                textShadow: "none",
              }}
              type='primary'
            >
              Close
            </Button>{" "}
          </SuccessModal>*/}{" "}
            <Modal
              visible={this.state.visible}
              style={{ textAlign: "center" }}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/logo.png`}
                alt='Logo'
                style={{ width: "65%", margin: "auto" }}
              />
              <h3
                style={{
                  marginTop: "1em",
                  color: "black",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                Thank you for contacting
                <br />
                <span style={{ color: "rgb(214, 54, 41)" }}>
                  CHERY KLERKSDORP.
                </span>{" "}
                <br />
                <br />
                <span style={{ color: "black ", fontSize: "17px" }}>
                  Our vehicle specialist will be in touch shortly.
                </span>{" "}
                <br />
              </h3>{" "}
              <br />
            </Modal>
          </div >
        )
        }
      </div>
    );
  }
}

export default BookService;
