import React, { useState, Component } from "react";
import styled from "styled-components";
import { Row, Col, Form, Input, Button, Modal, Select, Divider } from "antd";
import { Link } from "react-router-dom";
import { data } from "jquery";
//import { Modal as SuccessModal } from "react-responsive-modal";
//import "react-responsive-modal/styles.css";

const { Option } = Select;
const { TextArea } = Input;
const selectBefore = (
  <Form.Item
    name='title'
    rules={[{ required: true, message: false }]}
    style={{ marginBottom: "0px" }}
  >
    <Select placeholder='Title' className='select-before'>
      <Option value='Mrs'>Mr</Option>
      <Option value='Miss'>Miss</Option>
    </Select>
  </Form.Item>
);
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const StyledInput = styled(Input)`

  //padding-left: 1.2em !important;
  height: 44.5px !important;

    box-shadow:none !important;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid black !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  .ant-input-group-addon .ant-select {
    margin: 0em !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 20px !important;
  color: white !important;
}
.ant-select-arrow {

  color: white !important;

}
.ant-input-group-addon {
  padding: 0px !important;
  color: white !important;
  background-color: #c50d27 !important;
  border: 0px solid grey !important;
 
}
.ant-input-affix-wrapper {

  height: 43px !important;
  border: none !important;}
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;

    box-shadow:none !important;
}
  :active{
    border-color: red !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    
     box-shadow:none !important;
   
  }
  }
`;

const StyledTextArea = styled(TextArea)`
padding-left:0.4em !important;
  box-shadow:none !important;
background-color: white!important;
background-image: none;
border: none;
border: 0px solid black !important;
border: 1px solid black !important;
border-radius: 0px;
color: black!important;
//box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
.ant-input:focus, .ant-input-focused {
  border-color:  transparent !important;
  -webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  box-shadow: none !important;
  border-right-width: 1px;
  outline: 0;
}
.ant-input {
border-color: transparent !important;
-webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
box-shadow: none !important;
border-right-width: 1px;
outline: 0;
}
:placeholder {
  color: lightgrey !important;
}
:hover {
  border-color: red !important;

  box-shadow:none !important;
}
:active{
  border-color: red !important;
 
   box-shadow:none !important;
 
}
:focus{
  border-color: red !important;
  
   box-shadow:none !important;
 
}
}
`;

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };

    this.onFinish = this.onFinish.bind(this);
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  handleSubmit = (values) => {
    this.onFinish(values);
  };
  //onFinish = (values) => {
  //  (async () => {
  //    const rawResponse = await fetch(
  //      "https://leadsv3.cmscloud.co.za/api/lead/saveleadasync",
  //      {
  //        method: "POST",
  //        headers: {
  //          Accept: "application/json",
  //          "Content-Type": "application/json",
  //          Authorization: "Bearer " + "9D20B459-5A85-45C4-92B4-7D0F7E7FA50B",
  //        },
  //        body: JSON.stringify({
  //          //title: "React POST Request Example",
  //          lead: {
  //            dealerRef: "MT001",
  //            dealerFloor: "USED",
  //            //dealerSalesPerson: "",
  //            //region: "Gauteng",
  //            source: "MT-DEALERWEBSITE",
  //            transactionID: data.id,
  //            //details: values.details,
  //          },
  //          contact: {
  //            email: values.email,
  //            cellPhone: values.cellPhone,
  //            surname: values.surname,
  //            firstName: values.firstName,
  //            title: values.title,
  //          },
  //          //referrer: {
  //          //  email: values.email,
  //          //  cellPhone: values.cellPhone,
  //          //  surname: values.surname,
  //          //  firstName: values.firstName,
  //          //  title: values.title,
  //          //},
  //        }),
  //      }
  //    );
  //    const content = await rawResponse.json();

  //    console.log(content);
  //  })();
  //};
  //onFinish = (values) => {
  //  // Simple POST request with a JSON body using fetch
  //  const requestOptions = {
  //    method: "POST",
  //    headers: {
  //      "Content-Type": "application/json",
  //      Authorization: "Bearer " + "9D20B459-5A85-45C4-92B4-7D0F7E7FA50B",
  //    },
  //    body: JSON.stringify({
  //      //title: "React POST Request Example",
  //      lead: {
  //        dealerRef: "MT001",
  //        dealerFloor: "USED",
  //        //dealerSalesPerson: "",
  //        //region: "Gauteng",
  //        source: "MT-DEALERWEBSITE",
  //        transactionID: data.id,
  //        //details: values.details,
  //      },
  //      contact: {
  //        email: values.email,
  //        cellPhone: values.cellPhone,
  //        surname: values.surname,
  //        firstName: values.firstName,
  //        title: values.title,
  //      },
  //      //referrer: {
  //      //  email: values.email,
  //      //  cellPhone: values.cellPhone,
  //      //  surname: values.surname,
  //      //  firstName: values.firstName,
  //      //  title: values.title,
  //      //},
  //    }),
  //  };
  //  fetch(
  //    "https://aws-stage-leadsv3.cmscloud.co.za/api/lead/savelead",
  //    requestOptions
  //  )
  //    .then((response) => response.json())
  //    .then(async (res) => {
  //      if (!res.ok) {
  //        console.log("handle error");
  //        this.setState({
  //          code: "01",
  //          leadReference: "",
  //          status: "Failed",
  //          message: "Rejected - Lead DealerRef Invalid: 123M",
  //        });
  //      }
  //      console.log("handle success");
  //      this.setState({
  //        visible: true,
  //        submitDisabled: true,
  //        code: "00",
  //        leadReference: "fe7521e8-d48e-420d-a8d5-c9821a267a7c",
  //        status: "Success",
  //        message: "",
  //      });
  //    });
  //};
  onFinish = (values) => {
    const MSG = {
      to: [
        "leads@megatheron.co.za",
        //"leads@megatheron.co.za",
        //"allan@megatheron.co.za",
        //"xander@megatheron.co.za",
      ],
      from: "info@mtgroup.co.za",
      subject: "New Contact Enquiry",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/z5SfxC9/mtgroup.png" width="300" height="85" style="display: block; border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Contact Enquiry</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color:white;line-height:135%;">You have received a new Contact Enquiry from <br /> the Chery website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.title} ${values.firstName} ${values.surname}<br />
                          <strong> Number </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.cellPhone}<br />
                          <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.email}<br />
                          <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.message}<br />
                 
                      
                       
                             <br /><br />
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2024@Chery</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        visible: true,
        submitDisabled: true,
        title: "",
        firstName: "",
        surname: "",
        cellPhone: "",
        email: "",
        message: "",
      });
    });
  };

  render() {
    return (
      <div className='wrap-body-inner' style={{ maxWidth: "90em", margin: "auto" }}>

        <div className='banner-item banner-2x banner-bg-11 m-t-lg-100' style={{ backgroundColor: "white" }}>
          {/* <h6 style={{ textAlign: "center", marginTop: "0em", padding: 0 }}>
            Get in Touch
          </h6> */}
          <h1 style={{
            textAlign: window.isPhone ? "center" : "left",
            fontSize: window.isPhone ? "24px" : "40px",
            lineHeight: "0.2em",
            marginTop: "0em",
            color: "#343a40",
            fontWeight: 500,
          }}>
            <Divider type="vertical" style={{ height: "4em", marginTop: "-2.6em", borderLeft: "4px solid rgb(197, 13, 39)" }} /> LEAVE US A <span style={{ fontWeight: 500, color: "rgb(199, 0, 32)" }}>MESSAGE</span>

          </h1>
          {/* <p style={{ margin: "auto", padding: 0 }}>Let us call you back</p> */}
        </div>
        <section className={window.isPhone ? 'block-contact m-t-lg-0 m-t-xs-0 p-b-lg-0' : 'block-contact m-t-lg-0 m-t-xs-0 p-b-lg-50'} >
          <div className=''>
            <div className='row'>
              <div className='col-sm-6 col-md-6 col-lg-6 m-b-xs-30'>
                {/* <ul className='list-default'>
                  <li style={{ fontSize: 25, fontWeight: 700, marginBottom: "1em" }}>

                    CONTACT US
                  </li></ul> */}
                <Form
                  onFinish={this.handleSubmit}
                  initialValues={{
                    remember: true,
                  }}
                  name='wrap'
                  labelCol={{ flex: "110px" }}
                  labelAlign='left'
                  labelWrap
                  wrapperCol={{ flex: 1 }}
                  colon={false}

                >
                  <div className='col-sm-12 col-md-6 col-lg-6'>
                    <Form.Item
                      name='firstName'
                      rules={[{ required: true, message: false }]}
                      hasFeedback
                    >
                      <StyledInput
                        addonBefore={selectBefore}
                        placeholder='Name'
                      />
                    </Form.Item>
                  </div>
                  <div className='col-sm-12 col-md-6 col-lg-6'>
                    <Form.Item
                      name='surname'
                      rules={[{ required: true, message: false }]}
                      hasFeedback
                    >
                      <StyledInput placeholder='Surname' />
                    </Form.Item>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <Form.Item
                      name='cellPhone'
                      rules={[{ required: true, message: false }]}
                      hasFeedback
                    >
                      <StyledInput placeholder='Number' />
                    </Form.Item>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <Form.Item
                      name='email'
                      rules={[{ required: true, message: false, type: "email" }]}
                      hasFeedback
                    >
                      <StyledInput placeholder='Email' />
                    </Form.Item>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <Form.Item
                      name='message'
                      rules={[{ required: true, message: false }]}
                      hasFeedback
                    >
                      <StyledTextArea rows={4} placeholder="Enquiry" maxLength={100} />
                    </Form.Item>
                  </div>
                  {/*<div className='col-sm-12 col-md-12 col-lg-12'>
                      <Form.Item
                        name='details'
                        rules={[{ required: true, message: false }]}
                        hasFeedback
                      >
                        <StyledTextArea
                          placeholder='Enquiry'
                          showCount
                          maxLength={100}
                          style={{ height: 120, border: "1px solid black" }}
                          //onChange={onChange}
                        />
                      </Form.Item>
                    </div>*/}
                  <div className='col-lg-12 m-t-lg-12 text-center'>
                    <Form.Item>
                      <Button
                        style={{

                          border: "1px solid #c50d27",
                          fontSize: "15px",
                          fontWeight: 600,
                          borderRadius: "0.3em",
                          backgroundColor: "#c50d27",
                          color: "white",
                          height: "55px",
                          paddingLeft: "3em",
                          paddingRight: "3em",
                          textShadow: "none",
                          margin: "auto",
                          marginTop: "0em",
                          float: window.isPhone ? "" : "right"
                        }}
                        type='primary'
                        htmlType='submit'
                      >
                        CONTACT ME
                      </Button>{" "}
                      <p
                        style={{
                          color: "black",
                          //marginBottom: "2.5em",
                          marginTop: window.isPhone ? "1em" : "0em",
                          fontSize: "13px",
                          lineHeight: "1.5em",
                          float: window.isPhone ? "" : "left",
                          textAlign: window.isPhone ? "center" : "left"
                        }}
                      >
                        By submitting this form, you agree to our{" "}<br />
                        <span
                          style={{
                            fontSize: "13px",
                            textDecoration: "underline",
                          }}
                        >
                          <Link style={{ color: "black" }} to='/terms'>
                            Terms of Use
                          </Link>
                        </span>{" "}
                        and{" "}
                        <span
                          style={{
                            fontSize: "13px",
                            textDecoration: "underline",
                          }}
                        >
                          <Link style={{ color: "black" }} to='/privacy-policy'>
                            Privacy Statement
                          </Link>
                        </span>
                        .
                      </p>{" "}
                    </Form.Item>{" "}

                  </div>
                </Form >

              </div>
              <div className='col-sm-6 col-md-6 col-lg-6'>
                <div className='contact-form p-lg-30 p-xs-15  bg1-gray-2' >
                  <div className='contact-info p-xs-15 bg1-gray-2' style={{ textAlign: window.isPhone ? "center" : "left" }}>
                    {/* <ul className='list-default'>
                      <li style={{ fontSize: 25, fontWeight: 700 }}>

                        ADDRESS
                      </li></ul> */}
                    <div className='content' style={{ marginTop: "0em" }}>
                      <p>
                        Chery will always persist in technology-driven strategy, take the consumer experience as the core, and provide better products and services to consumers around the world through continuous innovation and resources integration.
                      </p>
                      <ul className='list-default'>
                        <li>
                          <i className='fa fa-map-marker' aria-hidden='true'></i>
                          {"  "}35 Leask Street,
                          Klerksdorp,
                          2570
                        </li>
                        <a href={"tel:018 518 0888"}>
                          <li>
                            <i className='fa fa-phone'></i>
                            {"  "}(018) 518 0888
                          </li>{" "}
                        </a>
                        <a href={"mailto:info@megatheron.co.za"}>
                          <li>
                            <i className='fa fa-envelope-o'></i>
                            <strong> General Enquiries:</strong> {"  "}
                            leads@megatheron.co.za
                          </li>
                        </a>
                        <a href={"mailto:wecare@megatheron.co.za"}>
                          <li>
                            <i className='fa fa-globe'></i>
                            <strong> Customer Care:</strong> {"  "}
                            wecare@megatheron.co.za
                          </li>
                        </a>
                      </ul>
                    </div>
                  </div>

                </div >
              </div >
            </div >
          </div >
        </section > {" "}
        < Modal
          visible={this.state.visible}
          style={{ textAlign: "center" }
          }
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/chery-white-header-dark.png`}
            alt='Logo'
            style={{ margin: "auto", width: "20em", marginTop: "2em" }}
          />
          <h3
            style={{
              marginTop: "1em",
              color: "black",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Thank you for contacting Chery.
            <br />


            <span style={{ color: "black ", fontSize: "17px" }}>
              Our vehicle specialist will be in touch shortly.
            </span>{" "}
            <br />
          </h3>{" "}
          <br />
        </Modal >
      </div >
    );
  }
}
export default ContactUs;
