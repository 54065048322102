import React from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Modal,
  Divider,
  Dropdown,
  Menu,
} from "antd";
import AllPosts from "./Blogs/all-posts";
import { DownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { fetchSingleStock } from "../redux/actions";
import { connect } from "react-redux";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      stock: props.stock,
      stock: [],
    };

    this.onFinish = this.onFinish.bind(this);
  }
  // componentDidMount() {
  //   fetch("https://www.vcsappcloud.com/mtgroup/api/stock/findallstockclient", {
  //     method: "GET",
  //     mode: "cors",
  //     headers: {
  //       "Content-Type": "application/json; charset=utf-8",
  //     },
  //   }).then(async (res) => {
  //     const result = await res.json();
  //     this.setState({
  //       stock: result.allStock,
  //     });
  //   });
  // }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  handleSubmit = (values) => {
    this.onFinish(values);
  };
  onFinish = (values) => {
    const MSG = {
      to: [
        // "leads@megatheron.co.za",
        "leads@megatheron.co.za",
        //"allan@megatheron.co.za",
        //"xander@megatheron.co.za",
      ],
      from: "info@mtgroup.co.za",
      //to: [  //"leads@megatheron.co.za", "monique@moniqs-interiors.com"],
      subject: "New Newsletter Enquiry",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/z5SfxC9/mtgroup.png" width="300" height="85" style="display: block; border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Newsletter Enquiry</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new Newsletter Enquiry from <br /> Chery website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br />
                          <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.email}<br />
                         
                        
                             
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2024@CHERY</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        visible: true,
        submitDisabled: true,

        email: "",
      });
    });
  };
  render() {
    const duplicateCheck = [];
    return (
      <div>
        <footer id="wrap-footer" className="desktop-view">
          <div className="footer-top">
            <div
              className="container-custom"
              style={{ maxWidth: "90em", textAlign: "left" }}
            >
              <div>
                <div className="row">
                  <div
                    className="col-xs-12 col-sm-7 col-md-7 m-t-lg-30"
                    style={{ textAlign: "left" }}
                  >
                    <a href={"tel:0185180888"}>
                      <h3
                        style={{
                          fontFamily: "TacticSans1",
                          fontSize: "16px",
                          letterSpacing: "0.1em",
                        }}
                      >
                        {" "}
                        <i className="fa fa-phone m-r-lg-10 icon"></i>
                        (018) 518 0888
                      </h3>
                    </a>

                    <a href={"mailto:leads@megatheron.co.za"}>
                      <h3
                        style={{
                          fontFamily: "TacticSans1",
                          fontSize: "16px",
                          letterSpacing: "0.1em",
                          marginTop: "-1.7em",
                        }}
                      >
                        {" "}
                        <i className="fa fa-envelope-o m-r-lg-10 icon"></i>
                        leads@megatheron.co.za
                      </h3>
                    </a>
                  </div>

                  <div
                    className="col-xs-12 col-sm-5 col-md-5 m-t-lg-30"
                    style={{ textAlign: "right" }}
                  >
                    <Link to={"/"}>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/chery-white-header-dark.png`}
                        alt="logo"
                        style={{
                          marginTop: "1em",
                          width: "15em",
                        }}
                      />
                    </Link>
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    borderTop: "1px solid rgba(0, 0, 0, .1)",
                    borderBottom: "1px solid rgba(0, 0, 0, .1)",
                    paddingBottom: "1em",
                    marginTop: "1em"
                  }}
                >
                  <div className="col-xs-12 col-sm-2 col-md-2 m-t-lg-20">
                    <Link to={"/"}>
                      <h3
                        style={{
                          fontFamily: "TacticSans",
                          fontSize: "16px",
                          letterSpacing: "0.1em",
                        }}
                      >
                        Home ⇀
                      </h3>
                    </Link>
                  </div>
                  <div className="col-xs-12 col-sm-2 col-md-2 m-t-lg-20">
                    <a href="#about-us">
                      <h3
                        style={{
                          fontFamily: "TacticSans",
                          fontSize: "16px",
                          letterSpacing: "0.1em",
                        }}
                      >
                        About ⇀
                      </h3>
                    </a>
                  </div>
                  <div className="col-xs-12 col-sm-2 col-md-2 m-t-lg-20">
                    <Link to={"/contactUs"}>
                      <h3
                        style={{
                          fontFamily: "TacticSans",
                          fontSize: "16px",
                          letterSpacing: "0.1em",
                        }}
                      >
                        Contact⇀
                      </h3>
                    </Link>
                  </div>
                  <div
                    className="col-xs-12 col-sm-2 col-md-6 m-t-lg-20"
                    style={{ textAlign: "right" }}
                  >
                    <Link to={"/tiggo-4-pro"}>
                      <h3
                        style={{
                          fontFamily: "TacticSans",
                          fontSize: "16px",
                          letterSpacing: "0.1em",
                        }}
                      >
                        Explore Chery Models ⇀
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="row" style={{ paddingBottom: "1em" }}>
                  <div className="col-xs-12 col-sm-2 col-md-2 m-t-lg-20">
                    <ul
                      className="social-icon list-inline "
                      style={{ marginTop: "0em" }}
                    >
                      <li style={{ marginRight: "1em" }}>
                        <a
                          href="https://www.facebook.com/MegaTheronGroup/"
                          target="_blank"
                        >
                          <i
                            style={{ color: "black", fontSize: "30px" }}
                            className="fa fa-facebook"
                          />
                        </a>
                      </li>
                      <li style={{ marginRight: "1em" }}>
                        <a>
                          <i
                            style={{ color: "black", fontSize: "30px" }}
                            className="fa fa-twitter"
                          />
                        </a>
                      </li>
                      <li style={{}}>
                        <a
                          href="https://www.linkedin.com/company/megatherongroup?originalSubdomain=za"
                          target="_blank"
                        >
                          <i
                            style={{ color: "black", fontSize: "30px" }}
                            className="fa fa-linkedin"
                          />
                        </a>
                      </li>
                    </ul>{" "}
                  </div>
                  <div
                    className="col-xs-12 col-sm-2 col-md-2 m-t-lg-20"
                    style={{ textAlign: "center" }}
                  >
                    <Link to="/legal-notice">
                      <h3 style={{ fontSize: "12px", color: "grey" }}>
                        Legal Notice
                      </h3>
                    </Link>
                  </div>
                  <div
                    className="col-xs-12 col-sm-2 col-md-2 m-t-lg-20"
                    style={{ textAlign: "center" }}
                  >
                    <Link to="/POPIA-manual">
                      <h3 style={{ fontSize: "12px", color: "grey" }}>
                        PAIA & POPIA
                      </h3>
                    </Link>
                  </div>
                  <div
                    className="col-xs-12 col-sm-2 col-md-2 m-t-lg-20"
                    style={{ textAlign: "center" }}
                  >
                    <Link to="/privacy-policy">
                      <h3 style={{ fontSize: "12px", color: "grey" }}>
                        Privacy Policy
                      </h3>
                    </Link>
                  </div>
                  <div
                    className="col-xs-12 col-sm-2 col-md-2 m-t-lg-20"
                    style={{ textAlign: "center" }}
                  >
                    <Link to="/terms">
                      <h3 style={{ fontSize: "12px", color: "grey" }}>
                        Terms of Use
                      </h3>
                    </Link>
                  </div>
                  <div
                    className="col-xs-12 col-sm-2 col-md-2 m-t-lg-20"
                    style={{ textAlign: "right" }}
                  >
                    <h3 style={{ fontSize: "12px", color: "grey" }}>
                      {" "}
                      © Copyright - Chery 2024
                    </h3>
                  </div>
                </div>
              </div>
              <Row style={{}}>
                <Col span={5} style={{ textAlign: "left", marginTop: "1em" }}>
                  <li style={{ color: "black", marginTop: "0em" }}>
                    <span style={{}}>In Affiliation with</span>
                    <br />
                    <a href="https://www.chery.megatheron.co.za/" target="_blank">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/mt-group.png`}
                        alt="logo"
                        style={{
                          width: "15em",
                          marginBottom: "1.5em",
                          marginTop: "-1em",
                        }}
                      />
                    </a>
                  </li>
                </Col>
                <Col span={19} style={{ textAlign: "left", marginTop: "1em" }}>
                  <p
                    style={{
                      fontSize: "11px",
                      fontWeight: 400,

                      lineHeight: "1em",
                      marginTop: "0.5em",
                      color: "rgba(0, 0, 0, .5)",
                      margin: "auto",
                      marginBottom: "1em",
                      maxWidth: "110em",
                      // borderTop: "1px solid black",
                      paddingTop: "0.5em",
                    }}
                  >
                    Terms and Conditions Apply. No responsibility is accepted
                    for any errors and omissions. The material on this website
                    is for informational purposes only. Chery International
                    (PTY) LTD reserves the right to change the information
                    referred to on this site at any time without prior notice.
                    Consult your Chery dealer for the latest information and
                    availability. Chery International (PTY) LTD reserves the
                    right to alter any details of specifications without notice.
                    The information contained in this website is an overview
                    intended for awareness purposes only. Images may show
                    specifications not available in South Africa images may be
                    shown for illustration purposes only. Please check model
                    availability and full specification details with your Chery
                    dealer. Neither Chery International (PTY) LTD nor a Chery
                    Dealer network can accept contractual responsibility for
                    your reliance on the information contained within this site.
                    ADDR:41.72.157.210 - PHPV: 7.4.29 - CMSV: 6.0.2
                  </p>{" "}
                </Col>
              </Row>{" "}
            </div>{" "}
          </div>
        </footer>{" "}
        <footer id="wrap-footer" className="mobile-view">
          <div
            className="footer-top"
            style={{
              textAlign: "center",
              backgroundColor: "rgba(211, 211, 211, 0.306)",
            }}
          >
            <div className="container-custom">
              <div >
                <div className="row">
                  <div
                    className="col-xs-12 col-sm-7 col-md-7 m-t-lg-30"
                    style={{ textAlign: "center" }}
                  >
                    <a href={"tel:0185180888"}>
                      <h3
                        style={{
                          fontFamily: "TacticSans1",
                          fontSize: "14px",
                          letterSpacing: "0.1em",
                        }}
                      >
                        {" "}
                        <i className="fa fa-phone m-r-lg-10 icon"></i>
                        (018) 518 0888
                      </h3>
                    </a>

                    <a href={"mailto:leads@megatheron.co.za"}>
                      <h3
                        style={{
                          fontFamily: "TacticSans1",
                          fontSize: "14px",
                          letterSpacing: "0.1em",
                          marginTop: "-1.7em",
                        }}
                      >
                        {" "}
                        <i className="fa fa-envelope-o m-r-lg-10 icon"></i>
                        leads@megatheron.co.za
                      </h3>
                    </a>
                  </div>
                  <div className="col-xs-12 col-sm-2 col-md-2 m-t-lg-30">
                    <h3>Navigate</h3>

                    <ul className="list-footer-default">
                      {/*<li>
                      <a>Brands</a>
                    </li>*/}
                      <li>
                        <Link
                          to={"/"}
                          style={{
                            fontFamily: "TacticSans",
                          }}
                        >
                          HOME ⇀
                        </Link>
                      </li>
                      <li>
                        <a
                          href="#about-us-mobile"
                          style={{
                            fontFamily: "TacticSans",
                          }}
                        >
                          ABOUT US ⇀
                        </a>
                      </li>
                      <li>
                        <Link
                          to={"/contactUs"}
                          style={{
                            fontFamily: "TacticSans",
                          }}
                        >
                          CONTACT US ⇀
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/tiggo-4-pro"}
                          style={{
                            fontFamily: "TacticSans",
                          }}
                        >
                          EXPLORE MODELS ⇀
                        </Link>
                      </li>
                      {/*<li>
                      <a>FAQ</a>
                    </li>*/}
                    </ul>
                  </div>{" "}
                  <div className="col-xs-12 col-sm-2 col-md-2 m-t-lg-20">
                    <h3>LEGAL</h3>

                    <ul className="list-footer-default">
                      <li>
                        <Link
                          to="/legal-notice"
                          style={{
                            fontFamily: "Helvetica",
                            fontSize: "14px",
                            color: "grey",
                          }}
                        >
                          LEGAL NOTICE
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/POPIA-manual"
                          style={{
                            fontFamily: "Helvetica",
                            fontSize: "14px",
                            color: "grey",
                          }}
                        >
                          PAIA & POPIA
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/privacy-policy"
                          style={{
                            fontFamily: "Helvetica",
                            fontSize: "14px",
                            color: "grey",
                          }}
                        >
                          PRIVACY POLICY
                        </Link>
                      </li>{" "}
                      <li>
                        <Link
                          to="/terms"
                          style={{
                            fontFamily: "Helvetica",
                            fontSize: "14px",
                            color: "grey",
                          }}
                        >
                          TERMS OF USE
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/terms"
                          style={{
                            fontFamily: "Helvetica",
                            fontSize: "14px",
                            color: "grey",
                          }}
                        >
                          © COPYRIGHT - CHERY 2024
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xs-12 col-sm-3 col-md-3 m-t-lg-10">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/chery-white-header-dark.png`}
                      alt="logo"
                      style={{
                        width: "12em",
                        marginBottom: "1.5em",
                        marginTop: "1em",
                      }}
                    />

                    <div className="row">
                      <div className="col-md-12">
                        <ul
                          className="social-icon list-inline "
                          style={{ marginTop: "1em" }}
                        >
                          <li style={{ marginRight: "1em" }}>
                            <a
                              href="https://www.facebook.com/MegaTheronGroup/"
                              target="_blank"
                            >
                              <i
                                style={{ color: "black", fontSize: "30px" }}
                                className="fa fa-facebook"
                              />
                            </a>
                          </li>
                          <li style={{ marginRight: "1em" }}>
                            <a
                            //href='https://twitter.com/MegatheronKLD'
                            //target='_blank'
                            >
                              <i
                                style={{ color: "black", fontSize: "30px" }}
                                className="fa fa-twitter"
                              />
                            </a>
                          </li>
                          <li style={{}}>
                            <a
                              href="https://www.linkedin.com/company/megatherongroup?originalSubdomain=za"
                              target="_blank"
                            >
                              <i
                                style={{ color: "black", fontSize: "30px" }}
                                className="fa fa-linkedin"
                              />
                            </a>
                          </li>
                        </ul>{" "}
                        <li style={{ color: "black", marginTop: "1em" }}>
                          <span style={{ marginLeft: "-7em" }}>
                            In Affiliation with
                          </span>
                          <br />
                          <a
                            href="https://www.chery.megatheron.co.za/"
                            target="_blank"
                          >
                            <img
                              src={`${process.env.PUBLIC_URL}/images/mt-group.png`}
                              alt="logo"
                              style={{
                                width: "15em",
                                marginBottom: "1.5em",
                                marginTop: "-1em",
                              }}
                            />
                          </a>
                        </li>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <Row>
              <Col span={24} style={{ textAlign: "center" }}>
                <p
                  style={{
                    fontSize: "11px",
                    fontWeight: 400,
                    lineHeight: "1em",
                    marginTop: "0.5em",
                    color: "black",
                    margin: "auto",
                    marginBottom: "1em",
                    maxWidth: "110em",
                    borderTop: "1px solid black",
                    padding: "1em",
                  }}
                >
                  Terms and Conditions Apply. No responsibility is accepted for
                  any errors and omissions. The material on this website is for
                  informational purposes only. Chery International (PTY) LTD
                  reserves the right to change the information referred to on
                  this site at any time without prior notice. Consult your Chery
                  dealer for the latest information and availability. Chery
                  International (PTY) LTD reserves the right to alter any
                  details of specifications without notice. The information
                  contained in this website is an overview intended for
                  awareness purposes only. Images may show specifications not
                  available in South Africa images may be shown for illustration
                  purposes only. Please check model availability and full
                  specification details with your Chery dealer. Neither Chery
                  International (PTY) LTD nor a Chery Dealer network can accept
                  contractual responsibility for your reliance on the
                  information contained within this site. ADDR:41.72.157.210 -
                  PHPV: 7.4.29 - CMSV: 6.0.2
                </p>{" "}
              </Col>
            </Row>
          </div>
        </footer>
      </div>
    );
  }
}
function mapStateToProps({ wishlistproducts }) {
  return { wishlistproducts };
}

export default connect(mapStateToProps, {
  fetchSingleStock,
})(Footer);
