import React, { Component } from "react";
import { Row, Col, Divider, Input, Button } from "antd";
import { Link } from "react-router-dom";

class LegalNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className='wrap-body-inner'>
        <div>
          <div className='row'>
            <div>
              <div
                style={{
                  position: "absolute",
                  margin: "auto",
                  left: 0,
                  top: "0em",
                  zIndex: -1,
                  right: 0,
                  boxShadow: "inset 0 0 100px 150px white",
                  position: "absolute",
                  width: "80vw",
                  height: "90vh",
                }}
              ></div>{" "}
              <div
                id='home'
                className='home-div'
                style={{
                  //maxHeight: "100em",
                  marginTop: "0em",
                  //width: "100vw",
                }}
              >
                {" "}
                <Row
                  className='home-row'
                  style={{
                    maxWidth: "90em",
                    margin: "auto",
                  }}
                >
                  <Col
                    sm={24}
                    md={24}
                    style={{
                      margin: "auto",
                      textAlign: "left",
                      marginTop: "13em",
                      marginBottom: "3em",
                    }}
                  >
                    <h2
                      style={{
                        marginTop: "0em",
                        color: "#3F3F3F",
                        fontWeight: 700,
                        fontSize: "30px",
                        padding: "30px",
                        paddingBottom: 20,
                        paddingTop: 0,
                        lineHeight: "1em",
                      }}
                    >
                      Terms of Use
                    </h2>

                    <div
                      style={{
                        margin: "auto",
                        fontWeight: 100,
                        color: "black",
                        fontWeight: 400,
                        fontSize: "17px",
                        maxWidth: "90em",
                        padding: "30px",
                        paddingTop: 0,
                        textAlign: "left",
                      }}
                    >
                      {/*Date:	*/}
                      DEFINITIONS:
                      <br />
                      <br />
                      "We, us or our" means <br />
                      <br />
                      "We, us or our" means: <br />
                      <br />
                      1. Mega Theron Group (Pty) Ltd with Reg. № 2020/570691/07
                      and Trading As:
                      <br />
                      • GWM & HAVAL Klerksdorp situated at 30 Leask Street,
                      Klerksdorp, 2571
                      <br />
                      • GWM & HAVAL Potchefstroom situated at 33 Nelson Mandela
                      Drive, Potchefstroom, 2531
                      <br />
                      • Kia & Mahindra Mahikeng situated at 2320 Aerodrome Road,
                      Mahikeng, 2735
                      <br />
                      • GWM & HAVAL Mahikeng situated at Shop 42, The Crossing,
                      C/O First & Sekame St, Mahikeng 2735
                      <br />
                      • GWM & HAVAL Lichtenburg situated at 4 Thabo Mbeki Drive,
                      Lichtenburg, 2740
                      <br />
                      <br />
                      2. Mega Theron Group (Pty) Ltd with Reg. № 2020/086396/07
                      and situated at 13 Joe Slovo Road, Freemanville,
                      Klerksdorp, 2571
                      <br />
                      <br />
                      3. MTG Klerksdorp (Pty) Ltd T/A Chery Klerksdorp with Reg.
                      № 2021/990251/07 and situated at C/O Bram Fischer & Leask
                      Street, Klerksdorp, 2571
                      <br />
                      <br />
                      "Our site or our website" means the site on which these
                      terms and conditions are displayed, including, but not
                      limited to the following websites (www.megatheron.co.za)
                      <br />
                      <br />
                      "You or your" means the person accessing or using our
                      site.
                      <br />
                      <br />
                      BEFORE USING OUR WEBSITES:
                      <br />
                      <br />
                      Before using our websites, you are advised to take the
                      time to read through this ‘Terms of Use’ Policy. <br />
                      <br />
                      ACCESS OF Terms: <br />
                      <br />
                      By accessing and/or using our websites, the user(s) agree
                      to be bound by the Terms set out in this policy. If you do
                      not wish to be bound by these Terms, do not access,
                      download, display, or copy the content on our websites.
                      <br />
                      <br />
                      UPDATING OF THESE TERMS:
                      <br />
                      <br />
                      We encouraged you to review this Policy frequently to stay
                      informed of any updates. We reserve the right to change
                      this policy at any time and for any reason. Your continued
                      use of this websites following the posting of changes or
                      updates will be considered notice of your acceptance of
                      these Terms, including any changes or updates.
                      <br />
                      <br />
                      NO WARRANTIES:
                      <br />
                      <br />
                      We give no warranties and/or guarantees as to: The
                      material on this website and its links is for information
                      only. We do not guarantee the accuracy, currency,
                      completeness or relevance of the content of the
                      advertisements for vehicles appearing on the websites,
                      containing but not limited to, the ownership, quality, and
                      legitimacy of any photographs. Furthermore, by using this
                      website you agree that we are not liable for any damages
                      of any kind, including but not limited to, the use or the
                      inability of use of the website, its content or its
                      services. This includes, without limitation, direct,
                      indirect, and consequential losses, loss of profits or
                      damages resulting from errors, lost data or business
                      interruption, even if we have been advised of the
                      possibility of such damages.
                      <br />
                      <br />
                      YOU RESPECT OUR RIGHT TO OUR INTELLECTUAL PROPERTY:
                      <br />
                      <br />
                      We own the copyright in all material on this website,
                      unless we specifically state otherwise. If you wish to use
                      this material and/or content for any reason other than
                      private, non-commercial use, you must ask our permission
                      to do so. You may not assume that you have our permission
                      to use our intellectual property simply because the
                      material is available on this website. Trademarks, service
                      marks logos and patented material used on this website are
                      the property of the owner and may not be used or licensed
                      without the permission. <br />
                      <br />
                      IT IS NOT PERMITTED TO:
                      <br />
                      <br />
                      • Use the website in any unlawful, fraudulent or abusive
                      manner.
                      <br />
                      • Use this website to build or change any databases.
                      <br />
                      • Reproduce, duplicate, copy or otherwise exploit material
                      on this website for a profitable purpose.
                      <br />
                      • Republish or quote any material from this website
                      without our permission (including republication on another
                      website).
                      <br />
                      TERMINATION: <br />
                      <br />
                      We may, restrict, suspend, or terminate this agreement and
                      your access to the website, without prior notice or
                      liability.
                      <br />
                      <br />
                      GOVERNING LAW:
                      <br />
                      <br />
                      These Terms and Conditions shall be governed and
                      interpreted in accordance with the laws of the Republic of
                      South Africa. Your continued use of the Website will
                      constitute your consent and submission to the jurisdiction
                      of the South African courts regarding all proceedings,
                      transactions, applications or the like instituted by
                      either party against the other, arising from any of these
                      Terms and Conditions.
                      <br />
                      CONTACT INFORMATION:
                      <br />
                      <br />
                      If you do not understand any of these Terms and want to
                      talk to us about it, please contact us by
                      info@megatheron.co.za.
                    </div>
                    <Divider
                      orientation='right'
                      style={{ borderTopColor: "rgba(0, 0, 0, 0.25)" }}
                    >
                      <Link to='/home'>
                        <Button
                          style={{
                            float: "right",
                            height: "2.7em",
                            width: "10em",
                            fontSize: "17px",
                            fontWeight: 500,
                            borderRadius: "0.4em",
                            color: "white",
                            border: "none",
                            marginTop: "1em",
                            marginBottom: "1em",
                            backgroundColor: "#c50d27",
                            textShadow: "none",
                            boxShadow: "none",
                          }}
                        >
                          Back to Home
                        </Button>
                      </Link>
                    </Divider>
                  </Col>
                </Row>{" "}
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LegalNotice;
